import InputRuleForm from "@components/InputRuleForm/form";
import { getValue } from "@utils/lodash";
import { Button } from "antd";
import React from "react";

const BroadcastVariableConfig = ({ request, setRequest, handleModal }: any) => {
  return (
    <div className="p-4">
      {getValue(request, `variableConfig.tags.data`, []).map(
        (item: any, index: any) => (
          <div key={index} className="d-flex align-items-center gap-2">
            <InputRuleForm
              // label={`{{${getValue(item, `tag`, "")}}}`}
              inputType="TEXT"
              value={getValue(item, `tag`, "")}
              name="name"
              placeholder={`${getValue(item, `tag`, "")}`}
              onChange={(e: any) =>
                setRequest((prevRequest: any) => {
                  return {
                    ...prevRequest,
                    variableConfig: {
                      ...prevRequest.variableConfig,
                      tags: {
                        ...prevRequest.variableConfig.tags,
                        data: prevRequest.variableConfig.tags.data.map(
                          (button: any, i: number) => {
                            if (index !== i) return button;
                            return {
                              ...button,
                              tag: e.target.value,
                            };
                          }
                        ),
                      },
                    },
                  };
                })
              }
              required
              className="mb-2"
            />
            <Button className="mt-3" onClick={() => handleModal(index)}>
              Pick Variable
            </Button>
          </div>
        )
      )}
    </div>
  );
};

export default BroadcastVariableConfig;
