import { QueryRequestHelper } from "@common/query-request-helper";
import HomeHeader from "@components/common/Header/HomeHeader/Header";
import {
  getDashboardLeads,
  getDashboardModule,
} from "@services/dashboard.service";
import * as React from "react";
import DashboardHeader from "./components/header";
import { getValue } from "@utils/lodash";
import { allPipelines } from "@services/pipeline.service";
import { listAllModules } from "@services/modules.service";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import {
  convertCurrentDate,
  convertDateTime,
  formatDateRange,
  getDateFunctions,
  getStartOfMonthDate,
} from "@common/date-helpers";
import "./dashboard.scss";
import Loader from "@components/common/Loader/loading";
import ArrowUpSvgComponent from "@assets/svg/arrow-up";
import ArrowDownSvgComponent from "@assets/svg/arrow-down";
// eslint-disable-next-line @typescript-eslint/no-empty-interface
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  Colors,
  PointElement,
  LineElement,
} from "chart.js";
import ChartDataLabels from "chartjs-plugin-datalabels";
import { Bar } from "react-chartjs-2";
import ProfileCircle from "@components/common/ProfileCircle";
import { numberToFixed, sortJSONObjectArray } from "@common/text-helpers";
import { dateFilters } from "@common/Date-Helpers/date-filters-helpers";
import { useStateContext } from "@context/profileProvider";
import GraphNoDataFound from "@components/common/NoData/graph-nodata";
import NotFoundFile from "@components/common/NoData/NotFoundFile";
import DashboardContact from "./components/contacts";
import { getDashboardContact } from "@services/dashboard.service";
import ListLoader from "@components/common/Loader";
import useDynamicTitle from "@context/useDynamicTitle";
import MainLayout from "@layouts/HomeLayout/NewLayout";
import { usePermissionContext } from "@context/permissionContext";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  ChartDataLabels,
  Colors
);

interface IDashboardProps {}

const Dashboard: React.FunctionComponent<IDashboardProps> = (props) => {
  const urlSearchParams = new URLSearchParams(window.location.search);
  const UrlParams = Object.fromEntries(urlSearchParams.entries());

  const location = useLocation();
  const navigate = useNavigate();
  const params = useParams();

  const { selectedModuleId } = useStateContext();
  const {
    tabPermissionList,
    subTabPermissionList,
    permissionAPITriggered,
  } = usePermissionContext();
  /* -------------------------------------------------------------------------- */
  /*                               UseState Section                             */
  /* -------------------------------------------------------------------------- */
  React.useEffect(() => {
    if (Object.keys(UrlParams).length === 0 && permissionAPITriggered) {
      getModules();
    }
  }, [permissionAPITriggered]);

  React.useEffect(() => {
    if (Object.keys(UrlParams).length > 0 && permissionAPITriggered) {
      getModules();
      if (getValue(UrlParams, `module`, "")) {
        setSelectedModule(getValue(UrlParams, `module`, ""));
      }

      if (getValue(UrlParams, `pipeline`, "")) {
        setSelectedPipeline(getValue(UrlParams, `pipeline`, ""));
      }

      if (getValue(UrlParams, `date_range`, "")) {
        setSelectedDateRange(getValue(UrlParams, `date_range`, ""));
      } else {
        setSelectedDateRange(getStartOfMonthDate());
      }
    }
  }, [
    window.location.href,
    getValue(location, `key`, ""),
    permissionAPITriggered,
  ]);
  /* -------------------------------------------------------------------------- */
  /*                               API Section                                  */
  /* -------------------------------------------------------------------------- */

  const getDashboardOverviewFun = async () => {
    let payload = {
      date_range: "",
    };
    let queryRequest = QueryRequestHelper(payload);
    try {
    } catch (error) {}
  };

  const [selectedModule, setSelectedModule] = React.useState("");
  const [allModules, setAllModules] = React.useState([]);
  const getModules = async () => {
    try {
      let payload = {};
      let queryRequest = QueryRequestHelper(payload);
      let resp = await listAllModules(queryRequest);
      if (resp) {
        let list =
          getValue(resp, `data.length`, 0) > 0
            ? getValue(resp, `data`, []).map((item: object) => ({
                ...item,
                value: getValue(item, `api_name`, ""),
                name: getValue(item, `api_name`, ""),
                label: getValue(item, `label`, ""),
                permission_key:
                  getValue(item, `api_name`, "") === "companies"
                    ? `crm_company`
                    : `crm_${getValue(item, `api_name`, "")}`,
              }))
            : [];
        let allNew = list.filter(
          (item: object) =>
            getValue(item, `name`, "") !== "calls" &&
            getValue(item, `name`, "") !== "meetings"
        );
        let all = allNew.filter((item: object) =>
          subTabPermissionList.includes(getValue(item, `permission_key`, ""))
        );
        setAllModules(sortJSONObjectArray(all, "seq_num"));
        setSelectedModule(
          getValue(UrlParams, `module`, "")
            ? getValue(UrlParams, `module`, "")
            : getValue(all, `[${0}].api_name`, "")
        );
        if (
          getValue(UrlParams, `module`, "") === "tasks" ||
          getValue(all, `[${0}].api_name`, "") === "tasks"
        ) {
          getDashboardModuleFun(
            getValue(UrlParams, `module`, "")
              ? getValue(UrlParams, `module`, "")
              : getValue(all, `[${0}].api_name`, ""),
            ""
          );
        } else {
          getAllPipelines(
            getValue(UrlParams, `module`, "")
              ? getValue(UrlParams, `module`, "")
              : getValue(all, `[${0}].api_name`, ""),
            list
          );
        }
      }
    } catch (error) {}
  };

  const [isLoading, setIsLoading] = React.useState(false);
  const [selectedPipeline, setSelectedPipeline] = React.useState("");
  const [pipelines, setAllPipelines] = React.useState([]);
  const getAllPipelines = async (code: string, allModules: any) => {
    try {
      setIsLoading(true);
      let payload = {
        // module_name: code,
      };

      let moduleInfo: any =
        getValue(allModules, `length`, 0) > 0 &&
        allModules.find(
          (item: object) => getValue(item, `api_name`, "") === code
        );
      let queryRequest = QueryRequestHelper(payload);
      let resp = await allPipelines(
        getValue(moduleInfo, `id`, ""),
        queryRequest
      );
      if (getValue(resp, `data.length`, 0) > 0) {
        let list = getValue(resp, `data`, []).map((item: object) => ({
          ...item,
          value: getValue(item, `api_name`, ""),
          label: getValue(item, `label`, ""),
        }));
        setAllPipelines(list);
        setSelectedPipeline(
          getValue(UrlParams, `pipeline`, "")
            ? getValue(UrlParams, `pipeline`, "")
            : getValue(list, `[${0}].id`, "")
        );
        getDashboardModuleFun(
          code,
          getValue(UrlParams, `pipeline`, "")
            ? getValue(UrlParams, `pipeline`, "")
            : getValue(list, `[${0}].id`, "")
        );
        setIsLoading(false);
      } else {
        setIsLoading(false);
      }
    } catch (error) {
      setIsLoading(false);
    }
  };

  const [dates] = React.useState(getDateFunctions());
  const [dashboardInfo, setDashboardInfo] = React.useState({});
  const [dashboardLoading, setDashboardLoading] = React.useState(true);
  const [selectedDateRange, setSelectedDateRange] = React.useState("");
  const [contactInfo, setContactInfo] = React.useState({});
  const getDashboardModuleFun = async (module: string, pipeline: String) => {
    try {
      // if(!getValue(UrlParams, `owner_id`, "")){
      //   setDashboardLoading(true);
      // }else{
      //   setDashboardLoading(false);
      // }
      setDashboardLoading(true);
      let resp;
      if (module === "contacts") {
        let payload = {
          // date_range: selectedDateRange ? selectedDateRange : getStartOfMonthDate(),
          date_range: getValue(UrlParams, `date_range`, "")
            ? getValue(UrlParams, `date_range`, "")
            : getValue(dateFilters, `[${0}].value`, ""),
          api_name: getValue(UrlParams, `module`, "")
            ? getValue(UrlParams, `module`, "")
            : module,
          pipeline_id: getValue(UrlParams, `pipeline`, "")
            ? getValue(UrlParams, `pipeline`, "")
            : pipeline,
          owner_id: getValue(UrlParams, `owner_id`, ""),
        };
        let queryRequest = QueryRequestHelper(payload);
        resp = await getDashboardContact(queryRequest);
        if (resp) {
          setContactInfo(getValue(resp, `data`, {}));
          setDashboardLoading(false);
        } else {
          setDashboardLoading(false);
        }
      } else if (module === "leads") {
        let payload = {
          // date_range: selectedDateRange ? selectedDateRange : getStartOfMonthDate(),
          date_range: getValue(UrlParams, `date_range`, "")
            ? getValue(UrlParams, `date_range`, "")
            : getValue(dateFilters, `[${0}].value`, ""),
          api_name: getValue(UrlParams, `module`, "")
            ? getValue(UrlParams, `module`, "")
            : module,
          pipeline_id: getValue(UrlParams, `pipeline`, "")
            ? getValue(UrlParams, `pipeline`, "")
            : pipeline,
          owner_id: getValue(UrlParams, `owner_id`, ""),
        };
        let queryRequest = QueryRequestHelper(payload);
        resp = await getDashboardLeads(queryRequest);
        if (resp) {
          setContactInfo(getValue(resp, `data`, {}));
          setDashboardLoading(false);
        } else {
          setDashboardLoading(false);
        }
      } else {
        let payload = {
          // date_range: selectedDateRange ? selectedDateRange : getStartOfMonthDate(),
          date_range: getValue(UrlParams, `date_range`, "")
            ? getValue(UrlParams, `date_range`, "")
            : getValue(dateFilters, `[${0}].value`, ""),
          api_name: getValue(UrlParams, `module`, "")
            ? getValue(UrlParams, `module`, "")
            : module,
          pipeline_id: getValue(UrlParams, `pipeline`, "")
            ? getValue(UrlParams, `pipeline`, "")
            : pipeline,
        };
        let queryRequest = QueryRequestHelper(payload);
        resp = await getDashboardModule(queryRequest);
        if (resp) {
          const groupedData = getValue(resp, `data`, []).reduce(
            (result: any, item: any) => {
              const type = item.type;
              if (!result[type]) {
                result[type] = [];
              }
              result[type].push(item);
              return result;
            },
            {}
          );
          setDashboardInfo(groupedData);
          setDashboardLoading(false);
        } else {
          setDashboardLoading(false);
        }
      }
    } catch (error) {
      setDashboardLoading(false);
    }
  };

  /* -------------------------------------------------------------------------- */
  /*                               Onchange section                             */
  /* -------------------------------------------------------------------------- */

  const getDateFilterName = () => {
    let dateInfo = dateFilters.filter(
      (item: any) =>
        getValue(item, `value`, "") === getValue(UrlParams, `date_range`, "")
    );
    return getValue(dateInfo, `[${0}].label`, "")
      ? getValue(dateInfo, `[${0}].label`, "")
      : getValue(UrlParams, `date_range`, "")
      ? formatDateRange(getValue(UrlParams, `date_range`, ""))
      : "Today";
  };

  /* -------------------------------------------------------------------------- */
  /*                                 Chart section                              */
  /* -------------------------------------------------------------------------- */
  const options = {
    responsive: true,
    barPercentage: 0.15,
    bezierCurve: false,
    maintainAspectRatio: false, //width
    plugins: {
      title: {
        display: false,
        text: "Chart.js Bar Chart - Stacked",
      },
      colors: {
        forceOverride: true,
      },
    },
    scales: {
      x: {
        stacked: true,
      },
      y: {
        stacked: true,
      },
    },
  };

  const baroptions: any = {
    responsive: true,
    bezierCurve: false,
    barPercentage: 0.15,
    maintainAspectRatio: false, //width
    plugins: {
      legend: {
        display: false,
        position: "top" as const,
      },
      title: {
        display: false,
        text: "Chart.js Bar Chart",
      },
    },
  };
  /* -------------------------------------------------------------------------- */
  /*                                  Date section                              */
  /* -------------------------------------------------------------------------- */

  const filtered: any = React.useMemo(() => {
    if (dateFilters.length > 0) {
      return (
        dateFilters.find(
          (item: object) => getValue(item, `value`, "") === selectedDateRange
        ) || null
      );
    }
    return null;
  }, [getValue(UrlParams, `date_range`, "")]);
  const handleNavigateModule = (item: object) => {
    // navigate(
    //   `/${getValue(params, `orgId`, "")}/crm/${selectedModule}/detail/${getValue(
    //     item,
    //     `id`,
    //     ""
    //   )}/crm/${selectedModule}/detail/${getValue(item, `id`, "")}`
    // );
  };

  const handleChangeUser = (userId: string) => {
    let payload = {
      ...UrlParams,
      page_no: 1,
      owner_id: userId,
    };
    let queryRequest = QueryRequestHelper(payload);
    navigate(`${window.location.pathname}?${queryRequest}`);
  };
  useDynamicTitle("Dashboard");
  return (
    <MainLayout {...props} isLoading={dashboardLoading}>
      <DashboardHeader
        //module
        allModules={allModules}
        selectedModule={selectedModule}
        //pipelines
        pipelines={pipelines}
        selectedPipeline={selectedPipeline}
        isLoading={isLoading}
        //date
        selectedDateRange={selectedDateRange}
        setDashboardLoading={setDashboardLoading}
      />
      {/* {dashboardLoading ? (
        <ListLoader />
      ) : ( */}
      <div className="main-container">
        {selectedModule === "contacts" || selectedModule === "leads" ? (
          <DashboardContact
            contactInfo={contactInfo}
            handleChangeUser={handleChangeUser}
            selectedModule={selectedModule}
          />
        ) : (
          <>
            <div className="box-container">
              {getValue(dashboardInfo, `kpi`, []).map((box: any) => (
                <div key={box.id} className="box">
                  <div className="d-flex align-items-center">
                    <h6 className="header_text__16">
                      {getValue(box, `title`, "")}
                    </h6>
                    <p className="mx-3">{" - "}</p>
                    <h6 className="header_text__16">{getDateFilterName()}</h6>
                  </div>
                  <div className="mt-2 d-flex align-items-center">
                    <p className="small_text__26">
                      {getValue(box, `value`, "")}
                    </p>
                    <div className="d-flex align-items-center">
                      <div className="ms-3">
                        {getValue(box, `objective`, "") === "increase" ? (
                          <ArrowUpSvgComponent size={50} />
                        ) : (
                          <ArrowDownSvgComponent size={50} />
                        )}
                      </div>
                      <p
                        className="header_text__16"
                        style={{
                          color:
                            getValue(box, `objective`, "") === "increase"
                              ? "#23AE73"
                              : "#DA4F4F",
                        }}
                      >
                        {getValue(box, `diff_percentage`, "")
                          ? numberToFixed(
                              getValue(box, `diff_percentage`, ""),
                              2
                            )
                          : 0}{" "}
                        %
                      </p>
                    </div>
                  </div>
                  <div className="mt-2">
                    {getValue(filtered, `old`, "") && (
                      <p className="sub_text__12">
                        {getValue(filtered, `old`, "")} :{" "}
                        {getValue(box, `previous_value`, "")
                          ? getValue(box, `previous_value`, "")
                          : 0}
                      </p>
                    )}
                  </div>
                </div>
              ))}
            </div>

            <div className="box-graph-container">
              {getValue(dashboardInfo, `stacked_graph`, []).map((box: any) =>
                getValue(box, `data.labels.length`, 0) > 0 ? (
                  <div key={box.id} className="box-graph">
                    <Bar options={options} data={getValue(box, `data`, {})} />
                  </div>
                ) : (
                  <div key={box.id} className="box-graph">
                    <GraphNoDataFound
                      title={
                        "No data is available for the selected time frame."
                      }
                    />
                  </div>
                )
              )}
              {/* {getValue(dashboardInfo, `bar_graph`, []).map((box: any) =>
              getValue(box, `data.labels.length`, 0) > 0 ? (
                <div key={box.id} className="box-graph">
                  <Bar options={baroptions} data={getValue(box, `data`, {})} />
                </div>
              ) : (
                <div key={box.id} className="box-graph">
                  <NotDataFound
                    title={"No data is available for the selected time frame."}
                  />
                </div>
              )
            )} */}
            </div>

            <div className="box-graph-container">
              {getValue(dashboardInfo, `bar_graph`, []).map((box: any) =>
                getValue(box, `data.labels.length`, 0) > 0 ? (
                  <div key={box.id} className="box-graph">
                    <Bar
                      options={baroptions}
                      data={getValue(box, `data`, {})}
                    />
                  </div>
                ) : (
                  <div key={box.id} className="box-graph">
                    <GraphNoDataFound
                      title={
                        "No data is available for the selected time frame."
                      }
                    />
                  </div>
                )
              )}
            </div>
            <div className="box-list-container">
              {getValue(dashboardInfo, `list`, []).map((box: any) => {
                return (
                  <div key={box.id} className="box-list">
                    <div className=" align-items-center">
                      <h6 className="header_text__16">
                        {getValue(box, `title`, "")}
                      </h6>
                      <div className="mt-4 ">
                        {getValue(box, `data.length`, 0) > 0 ? (
                          <>
                            {getValue(box, `data`, []).map((item: object) => {
                              return (
                                <div
                                  onClick={() => handleNavigateModule(item)}
                                  className="mb-2 d-flex align-items-center p-2 graph-list-container"
                                >
                                  <ProfileCircle
                                    value={getValue(item, `title`, "")}
                                  />
                                  <div>
                                    <h6 className="header_text__16">
                                      {getValue(item, `title`, "")}
                                    </h6>
                                    <p className="small_text__14">
                                      {selectedModule === "sales"
                                        ? convertCurrentDate(
                                            getValue(item, `subtitle`, "")
                                          )
                                        : getValue(item, `subtitle`, "")}
                                    </p>
                                  </div>
                                </div>
                              );
                            })}
                          </>
                        ) : (
                          <NotFoundFile />
                        )}
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          </>
        )}
      </div>
      {/* )} */}
    </MainLayout>
  );
};

export default Dashboard;
