import InfoSvgComponent from "@assets/svg/info";
import { emojisList } from "@common/emojis";
import { handleTextArrayChange } from "@common/handleChange";
import DraftJSRefEditor from "@components/custom/DraftJSEditor/draft-ref-editor";
import InputRuleForm from "@components/InputRuleForm/form";
import { getValue } from "@utils/lodash";
import { Dropdown, MenuProps, Tooltip } from "antd";
import AIStars from "@components/common/Loader/ai-stars";
import { getStringContentLength } from "@common/markdown/html-to-text-parser";

const TemplateBody = (props: any) => {
  const {
    request,
    setRequest,
    handleModal,
    handleChangeEditor,
    toggleAiModal,
    aiRephraseMethods,
    isRephraseLoading,
  } = props;

  const items: MenuProps["items"] = aiRephraseMethods.map(
    (method: any, index: any) => ({
      key: `${index + 1}`,
      label: method,
      onClick: () => toggleAiModal(method),
      disabled: isRephraseLoading === true,
    })
  );

  return (
    <>
      <div className="border-bottom">
        <div className="d-flex justify-content-between mt-3">
          <div className="d-flex align-items-center gap-2">
            <h6 className="">
              Message Body <span style={{ color: "red" }}>*</span>
            </h6>
            <Tooltip
              title="Make your messages personal using variables like [[name]] and get more replies."
              className="mb-1"
            >
              <span>
                <InfoSvgComponent color="#676767" size="16" />
              </span>
            </Tooltip>
          </div>
          <button
            onClick={() => {
              handleModal();
            }}
            className="dashboard-button--primary dashboard-button  hover-text touchable-text-white px-4 py-2"
          >
            Add variable
          </button>
        </div>
        <div className="w-100 mt-1 input-with-length">
          <div className="py-3">
            <DraftJSRefEditor
              editorState={getValue(request, `body`, "")}
              handleChangeEditor={handleChangeEditor}
              name="content"
              placeholder={`Hi [[name]]!🖐\n\nThe awesome products 😍 from [[brand_name]] are waiting to be yours!\n Get your products here👇`}
              editorStyle={{
                background: "white",
                paddingLeft: "20px",
                paddingTop: "20px",
                // height: "30vh",
              }}
              focusColor={"#3fd1a9"}
              toolbar={{
                options: ["inline", "emoji"],
                inline: {
                  options: ["bold", "italic"],
                },
                emoji: {
                  emojis: emojisList,
                },
              }}
              focus
              // autoFocus
              hideFocus
              editorClassName={"broadcast-editorClassName"}
            />
            <p className="small_text__12 mt-1 text-end">
              {getStringContentLength(getValue(request, `body`, ""))}/1024
            </p>
          </div>
          <div className="d-flex justify-content-end">
            <Tooltip
              title={
                getValue(request, "body", "").length < 12
                  ? "Add message body to let AI help you"
                  : ""
              }
            >
              <Dropdown
                menu={{ items }}
                disabled={getValue(request, "body", "").length < 12}
              >
                <a onClick={(e) => e.preventDefault()} className="w-auto">
                  <div className="ai-button-wrapper">
                    <button className="ai-button" type="button">
                      <div className="heart-beat-anim">
                        <AIStars />
                      </div>
                      AI Rewrite
                    </button>
                    <div className="ai-button-bg"></div>
                  </div>
                </a>
              </Dropdown>
            </Tooltip>
          </div>
        </div>
      </div>
      {getValue(request, `customTags.length`, 0) > 0 && (
        <div className="border-bottom">
          <h6 className="mt-3">Sample column</h6>
          <p className="my-2 greyed-description">
            Your fields will appear here once an attribute is added
          </p>
          <div className="input-with-length">
            {getValue(request, `customTags`, []).map(
              (item: any, index: number) => {
                return (
                  <InputRuleForm
                    inputType="TEXT"
                    placeholder={`Enter sample for ${getValue(
                      item,
                      `placeholder`,
                      ""
                    )}`}
                    name="sample"
                    onChange={(e: any) =>
                      handleTextArrayChange(index, e, "customTags", setRequest)
                    }
                    value={getValue(item, `sample`, "")}
                  />
                );
              }
            )}
          </div>
        </div>
      )}
    </>
  );
};

export default TemplateBody;
