import { GlobalOutlined, WhatsAppOutlined } from "@ant-design/icons";
import EditSvgComponent from "@assets/svg/edit";
import { getValue } from "@utils/lodash";
import { Tooltip } from "antd";
import { useNavigate, useParams } from "react-router-dom";

const BotLibraryTableData = (props: any) => {
  const { list, toggleBotTypeModal } = props;
  const navigate = useNavigate();
  const params = useParams();

  const handleNavigate = (id: string) => {
    navigate(`/${getValue(params, `orgId`, "")}/chatbot/logs/${id}`);
  };
  const handleEditBot = (id: string) => {
    navigate(
      `/${getValue(
        params,
        `orgId`,
        ""
      )}/chatbot/builder/create-flow/${id}/library`
    );
  };

  return (
    <div className=" w-100 add-overflow-y mt-3 bot_container">
      {/* {getValue(list, `length`, 0) > 0 ? ( */}
      <div className="bot-list-wrapper p-4 gap-4 pb-5">
        {list.map((item: object, key: number) => {
          return (
            <div className="bot-card-container p-4" key={key}>
              <div
                className=" touchable-text d-flex align-items-center gap-3"
                onClick={() => handleNavigate(getValue(item, `id`, ""))}
              >
                <img
                  src={
                    getValue(item, `avatar`, "")
                      ? getValue(item, "avatar", "")
                      : "/images/bot-loader.gif"
                  }
                  width={50}
                  height={50}
                  className="rounded-5"
                  style={{ background: "#e7e7e7" }}
                />
                <div>
                  <h6 className="header_blue_text__16 ">
                    {getValue(item, `name`, "")}
                  </h6>
                  <p className="small_text__13">
                    {getValue(item, `description`, "")}
                  </p>
                </div>
              </div>
              <div className="d-flex gap-3 mt-4 justify-content-between align-items-end">
                {getValue(item, `platform`, "") === "WHATSAPP" ? (
                  <Tooltip title="Whatsapp BOT">
                    <WhatsAppOutlined style={{ color: "#25D366" }} />
                  </Tooltip>
                ) : (
                  <Tooltip title="Web BOT">
                    <GlobalOutlined style={{ color: "#1DA1F2" }} />
                  </Tooltip>
                )}
                <div className="d-flex align-items-center gap-3">
                  {getValue(props, `permissions`, []).includes("update") && (
                    <Tooltip title="Edit">
                      <div
                        onClick={() => handleEditBot(getValue(item, `id`, ""))}
                        className="cursor-pointer"
                      >
                        <EditSvgComponent color="#707070" />
                      </div>
                    </Tooltip>
                  )}
                  {/* <Tooltip title="Responses">
                    <div
                      onClick={() => handleNavigate(getValue(item, `id`, ""))}
                      className="cursor-pointer"
                    >
                      <ModulesAndFieldsSvgComponent />
                    </div>
                  </Tooltip>
                  <Tooltip title="Clone">
                    <div
                      onClick={() =>
                        props.handleClone(getValue(item, `id`, ""))
                      }
                      className="cursor-pointer"
                    >
                      <CopySvgComponents size="20" />
                    </div>
                  </Tooltip> */}
                </div>
              </div>
            </div>
          );
        })}
      </div>
      {/* ) : (
        <BotNoDataPage />
      )} */}
    </div>
  );
};

export default BotLibraryTableData;
