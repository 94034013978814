import { useEffect, useState } from "react";
import HomeHeader from "@components/common/Header/HomeHeader/Header";
import { Tabs } from "antd";
import { QueryRequestHelper } from "@common/query-request-helper";
import { getValue } from "@utils/lodash";
import TemplateMessages from "./TemplateMessages";
import BroadcastHistory from "./BroadcastHistory";
import { useNavigate } from "react-router-dom";
import PipelineSubHeader from "@components/Pages/Pipeline/SubHeader";
import useDynamicTitle from "@context/useDynamicTitle";
import MainLayout from "@layouts/HomeLayout/NewLayout";

const { TabPane } = Tabs;

function Broadcast(props: any) {
  const navigate = useNavigate();
  const urlSearchParams = new URLSearchParams(window.location.search);
  const UrlParams = Object.fromEntries(urlSearchParams.entries());

  const [activeTab, setActiveTab] = useState("1");

  const views = [
    {
      id: "1",
      name: "Broadcast History",
    },
    {
      id: "2",
      name: "Template Messages",
    },
  ];

  useEffect(() => {
    if (Object.keys(UrlParams).length > 0) {
      setActiveTab(getValue(UrlParams, `activeTab`, "1"));
    }
  }, [window.location.href]);

  const handleChangeTab = (key: string) => {
    let payload = {
      ...UrlParams,
      page_no: 1,
      activeTab: key,
    };
    let queryRequest = QueryRequestHelper(payload);
    navigate(`${window.location.pathname}?${queryRequest}`);
    setActiveTab(key);
  };
  useDynamicTitle("Broadcast");
  return (
    <MainLayout {...props}>
      <PipelineSubHeader
        subHeaderListTitle="Broadcast"
        isRightArrowVisible={true}
        isSubmenuListAvailable={true}
        hideClose={true}
        pipeline={false}
      />
      <div className="d-flex">
        <div className="manageFieldRight w-100 px-5 pt-3">
          <Tabs
            activeKey={activeTab}
            onChange={handleChangeTab}
            type="card"
            className="broadcast-tabs"
          >
            {views.map((item) => (
              <TabPane tab={item.name} key={item.id}>
                {item.id === "1" && (
                  <BroadcastHistory
                    permissions={getValue(props, `permissions`, [])}
                  />
                )}
                {item.id === "2" && (
                  <TemplateMessages
                    permissions={getValue(props, `permissions`, [])}
                  />
                )}
              </TabPane>
            ))}
          </Tabs>
        </div>
      </div>
    </MainLayout>
  );
}

export default Broadcast;
