import { useParams, NavLink, useLocation } from "react-router-dom";
import "./Header.scss";
import { getValue } from "@utils/lodash";
import HeaderSignin from "../HeaderSignin/HeaderSignin";
import { getStartOfMonthDate } from "@common/date-helpers";
import { useStateContext } from "@context/profileProvider";
import { Menu, Dropdown, Badge } from "antd";
import Logo from "@components/common/Logo/Logo";
import ChevronDown from "@assets/svg/chevron-down";
import { usePermissionContext } from "@context/permissionContext";

interface Module {
  label_singular: string;
  label: string;
  link: string;
  type: string;
  linkText: string;
  linkUrl: string;
  id: string;
}

export default function HomeHeader(props: any) {
  const { setOpenTour, tourRef1 } = props;
  const params = useParams();
  const location = useLocation();
  const { newNotification, modules, totalInboxCount, currentCRMUrl } =
    useStateContext();
  let date_range = getStartOfMonthDate();

  const { tabPermissionList, subTabPermissionList } = usePermissionContext();
  const navItems = [
    {
      name: "Home",
      link: `/${getValue(params, `orgId`, "")}/home`,
      isNewFeature: false,
    },
    {
      name: "Dashboard",
      link: `${
        getValue(params, `orgId`, "")
          ? `/${getValue(
              params,
              `orgId`,
              ""
            )}/dashboard?date_range=${date_range}`
          : "#"
      }`,
      permTab: "dashboard",
      isNewFeature: false,
    },
    {
      name: "CRM",
      link: `/${getValue(params, "orgId", "")}/crm/leads`,
      isDropdown: true,
      isNewFeature: false,
      permTab: "crm",
    },
    {
      name: "Broadcast",
      link: `/${getValue(params, "orgId", "")}/broadcast`,
      isNewFeature: true,
      permTab: "campaigns",
    },
    {
      name: "Inbox",
      link: `/${getValue(params, "orgId", "")}/inbox`,
      permTab: "universal_inbox",
    },
    {
      name: "Bots",
      link: `/${getValue(params, "orgId", "")}/chatbot`,
      isNewFeature: true,
      permTab: "chatbot",
    },
    // {
    //   name: "Properties",
    //   link: `/${getValue(params, "orgId", "")}/property`,
    //   isNewFeature: false,
    // },
  ];

  const renderDropdownMenu = (items: Module[]) => {
    const pipelineItems = items.filter(
      (item: any) =>
        item.type === "PIPELINE" &&
        subTabPermissionList.includes(
          `crm_${
            getValue(item, `api_name`, "") === "companies"
              ? "company"
              : getValue(item, `api_name`, "")
          }`
        )
    );
    const activityItems = items.filter(
      (item: any) =>
        item.type === "ACTIVITY" &&
        subTabPermissionList.includes(`crm_${getValue(item, `api_name`, "")}`)
    );
    return (
      <Menu className="navbar-dropdown-container">
        {pipelineItems.length > 0 && (
          <>
            <Menu.ItemGroup title="" className="navbar-dropdown-menu">
              {pipelineItems.map((dropdownItem) => {
                return (
                  <Menu.Item key={dropdownItem.id}>
                    <NavLink
                      to={dropdownItem.linkUrl}
                      className={`subnav_dropdown_item ${
                        location.pathname == dropdownItem.linkUrl
                          ? "active"
                          : ""
                      }`}
                    >
                      {dropdownItem.linkText}
                    </NavLink>
                  </Menu.Item>
                );
              })}
            </Menu.ItemGroup>
            <Menu.Divider className="navbar-dropwdown-divider" />
          </>
        )}
        {activityItems.length > 0 && (
          <Menu.ItemGroup className="navbar-dropdown-menu">
            {activityItems.map((dropdownItem) => (
              <Menu.Item key={dropdownItem.id}>
                <NavLink
                  to={dropdownItem.linkUrl}
                  className={`subnav_dropdown_item ${
                    location.pathname === dropdownItem.linkUrl ? "active" : ""
                  }`}
                >
                  {dropdownItem.linkText}
                </NavLink>
              </Menu.Item>
            ))}
          </Menu.ItemGroup>
        )}
      </Menu>
    );
  };
  return (
    <header className="header-wrapper">
      <div className="d-flex align-items-center">
        <Logo />
        <nav className="navbar_items__wrapper">
          {navItems.map((item: any, index) => (
            <>
              {tabPermissionList.includes(getValue(item, `permTab`, "")) ? (
                <div
                  key={index}
                  className="navbar_item"
                  ref={item.name === "CRM" ? tourRef1 : null}
                >
                  {item.isDropdown ? (
                    <Dropdown overlay={renderDropdownMenu(modules)}>
                      <NavLink
                        to={
                          getValue(item, `name`, "") === "CRM"
                            ? currentCRMUrl
                              ? currentCRMUrl
                              : item.link
                            : item.link
                        }
                        className={`navbar_nav__link ${
                          item.name === "CRM" &&
                          location.pathname == item.linkUrl
                            ? "active"
                            : ""
                        }`}
                        style={{
                          color: "#dadce0",
                          fontSize: "14px",
                          marginLeft: 8,
                        }}
                      >
                        <div className="me-2">{item.name}</div>
                        <ChevronDown color="#dadce0" size="12px" />
                      </NavLink>
                    </Dropdown>
                  ) : (
                    <NavLink
                      to={item.link}
                      className={`navbar_nav__link ${
                        location.pathname == item.linkUrl ? "active" : ""
                      }`}
                    >
                      {item.name}
                      {item.name === "Inbox" && (
                        <>
                          {
                            // <Badge dot offset={[10, 0]} />

                            totalInboxCount() > 0 && (
                              <div className="whatsapp-msg-notification">
                                <Badge
                                  color="#25d366"
                                  count={totalInboxCount()}
                                  size="small"
                                />
                              </div>
                            )
                          }
                        </>
                      )}
                      {item.isNewFeature && (
                        <div className="new-feature-tag">New</div>
                      )}
                    </NavLink>
                  )}
                </div>
              ) : (
                <div
                  key={index}
                  className="navbar_item"
                  ref={item.name === "CRM" ? tourRef1 : null}
                >
                  {item.isDropdown ? (
                    <Dropdown overlay={renderDropdownMenu(modules)}>
                      <NavLink
                        to={
                          getValue(item, `name`, "") === "CRM"
                            ? currentCRMUrl
                              ? currentCRMUrl
                              : item.link
                            : item.link
                        }
                        className={`navbar_nav__link ${
                          item.name === "CRM" &&
                          location.pathname == item.linkUrl
                            ? "active"
                            : ""
                        }`}
                        style={{
                          color: "#dadce0",
                          fontSize: "14px",
                          marginLeft: 8,
                        }}
                      >
                        <div className="me-2">{item.name}</div>
                        <ChevronDown color="#dadce0" size="12px" />
                      </NavLink>
                    </Dropdown>
                  ) : (
                    <NavLink
                      to={item.link}
                      className={`navbar_nav__link ${
                        location.pathname == item.linkUrl ? "active" : ""
                      }`}
                    >
                      {item.name}
                      {item.name === "Inbox" && (
                        <>
                          {
                            // <Badge dot offset={[10, 0]} />

                            totalInboxCount() > 0 && (
                              <div className="whatsapp-msg-notification">
                                <Badge
                                  color="#25d366"
                                  count={totalInboxCount()}
                                  size="small"
                                />
                              </div>
                            )
                          }
                        </>
                      )}
                      {item.isNewFeature && (
                        <div className="new-feature-tag">New</div>
                      )}
                    </NavLink>
                  )}
                </div>
              )}
            </>
          ))}
        </nav>
      </div>
      <HeaderSignin setOpenTour={setOpenTour} />
    </header>
  );
}
