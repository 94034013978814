import { getValue } from "@utils/lodash";
import { Button } from "antd";
import React, { Component } from "react";
class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { error: null, errorInfo: null };
  }

  componentDidCatch(error, errorInfo) {
    // Catch errors in any components below and re-render with error message
    this.setState({
      error: error,
      errorInfo: errorInfo,
    });
    // You can also log error messages to an error reporting service here
  }

  redirect = (type) => {
    if (type === 1) {
      window.location.href = "/dashboard/compliance";
    } else {
      window.location.reload();
    }
  };

  render() {
    if (this.state.errorInfo) {
      // Error path
      return (
        <>
          <header className="header-wrapper">
            <div className="container-fluid d-flex align-items-center justify-content-between">
              <div className="d-flex align-items-center cursor-pointer">
                <a href="" className="d-inline-block" onClick={this.redirect}>
                  <img
                    src={
                      getValue(localStorage, `organisation_logo`, "")
                        ? getValue(localStorage, `organisation_logo`, "")
                        : "/appzo_logo.png"
                    }
                    width="173"
                    height="52"
                    className="img-fluid header-wrapper__logo"
                  />
                </a>
              </div>
            </div>
          </header>
          <div
            style={{
              margin: 0,
              width: "100%",
              display: "flex",
              justifyContent: "center",
              textAlign: "center",
              marginTop: "30vh",
            }}
          >
            <div className="login-ext">
              <div className="logo">
                <a href="/dashboard/compliance">
                  {/* <img src="/images/rapid-sales-logo.svg" alt="Logo" /> */}
                  <img
                    src={
                      getValue(localStorage, `organisation_logo`, "")
                        ? getValue(localStorage, `organisation_logo`, "")
                        : "/appzo_logo.png"
                    }
                    alt="Logo"
                    style={{ height: "100px", width: "200px" }}
                  />
                </a>
              </div>
              <div className="d-table d-table__middle">
                <div>
                  <form autoComplete="off">
                    <div className="center-content">
                      <h2>Something’s gone wrong on our end.</h2>
                      <p>
                        We’re working feverishly to sort it out. Give us a
                        couple minutes and try again.{" "}
                        {/* Or call our customer support at 080 4112 2009 if you need immediate assistance. */}
                        Thank You.
                      </p>
                      <div className="d-flex align-items-center gap-2 justify-content-center mt-3">
                        <Button
                          size="large"

                          // onClick={(e) => this.redirect(2)}
                        >
                          <a href="/">Go home</a>
                        </Button>
                        <Button
                          size="large"
                          type="primary"
                          onClick={(e) => this.redirect(2)}
                        >
                          Try Again
                        </Button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>

            {/* <details style={{ whiteSpace: 'pre-wrap' }}>
              {this.state.error && this.state.error.toString()}
              <br />
              {this.state.errorInfo.componentStack}
            </details> */}
          </div>
        </>
      );
    }
    // Normally, just render children
    return this.props.children;
  }
}
export default ErrorBoundary;
