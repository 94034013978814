import {
  CheckCircleFilled,
  CheckCircleOutlined,
  InboxOutlined,
} from "@ant-design/icons";
import { handleDownloadOnClick } from "@common/downloadfile";
import SearchToolTip from "@components/custom/Dropdown/SearchTooltip";
import DragAndDropFileComponent from "@components/custom/FileUpload/file-drag-drop";
import { getValue } from "@utils/lodash";
import {
  Alert,
  Button,
  Collapse,
  CollapseProps,
  Table,
  Tag,
  UploadProps,
} from "antd";
import Dragger from "antd/es/upload/Dragger";
import React from "react";
import { toast } from "sonner";

const BroadcastContactsUpload = (props: any) => {
  const columns = [
    {
      title: "NAME",
      // dataIndex: "first_name",
      // key: "first_name",
      // render: (first_name: any, text: any) => (
      //   <div>
      //     {first_name} {text.last_name}
      //   </div>
      // ),
    },
    {
      title: "PHONE NUMBER",
      // dataIndex: "phone_number",
      // key: "phone_number",
    },
    {
      title: "MOBILE NUMBER",
      // dataIndex: "mobile_number",
      // key: "mobile_number",
    },
  ];

  const items: CollapseProps["items"] = [
    {
      key: "1",
      label: (
        <div className="d-flex align-items-center gap-2">
          <CheckCircleFilled style={{ color: "#DADCE0" }} />
          Download Sample file (Optional)
        </div>
      ),
      children: (
        <div>
          <p className="small_text__14">
            Name and Phone numbers ( with country code, e.g; +91) are mandatory
            fields
          </p>
          <div className="d-flex justify-content-end mt-2">
            <Button
              type={"primary"}
              onClick={() =>
                handleDownloadOnClick(props.downloadFile, "template")
              }
            >
              Download Template
            </Button>
          </div>
        </div>
      ),
    },
    {
      key: "2",
      label: (
        <div className="d-flex align-items-center gap-2">
          <CheckCircleFilled style={{ color: "#DADCE0" }} />
          Upload Data file
        </div>
      ),
      children: (
        <div>
          <p className="small_text__14">
            Please ensure you have updated the columns with necessary
            information in the file before uploading
          </p>
          <Dragger {...props} maxCount={1} className="pt-5">
            <p className="ant-upload-drag-icon">
              <InboxOutlined />
            </p>
            <p className="ant-upload-text">Choose file</p>
            <p className="ant-upload-hint">
              Drag and drop or choose a file to upload. Only .xlsx files are
              supported.
            </p>
          </Dragger>

          {/* <div className="mt-3 mb-2">
            <SearchToolTip
              label="label"
              // data={allConnectionList}
              selectKey={"value"}
              // value={getValue(request, `waAccountId`, "")}
              // onChange={(e: any) =>
              //   setRequest({
              //     ...request,
              //     waAccountId: getValue(e, `id`, ""),
              //   })
              // }
              name="Phone Number Format"
              placeholder="Choose number format"
              required
            />
          </div> */}
          <Alert
            message="“Without country code” the default format is +91"
            type="info"
            className="w-fit-content"
          />
        </div>
      ),
    },
    {
      key: "3",
      label: (
        <div className="d-flex align-items-center gap-2">
          <CheckCircleFilled style={{ color: "#DADCE0" }} />
          Upload Preview
        </div>
      ),
      children: (
        <div>
          <p className="small_text__14">Sample preview contact</p>
          <Table
            size="middle"
            bordered
            columns={columns}
            // dataSource={allList}
            rowKey="id"
            className="mt-3"

            // pagination={{
            //   current: page_no1,
            //   pageSize: limit1,
            //   total: totalCount1,
            //   showSizeChanger: true,
            //   pageSizeOptions: [
            //     "10",
            //     "20",
            //     "30",
            //     "40",
            //     "50",
            //     "60",
            //     "70",
            //     "80",
            //     "90",
            //     "100",
            //   ],
            //   onChange: (page_no, pageSize) => {
            //     handleChangePagination1(page_no, pageSize);
            //   },
            //   showTotal: (total, range) =>
            //     `${range[0]}-${range[1]} of ${total} items`,
            // }}
          />
          <div className="d-flex align-items-center gap-2 mt-3 justify-content-end">
            <Button size="large">Re-Upload Full Data</Button>
            <Button size="large" type="primary">
              Map columns
            </Button>
          </div>
        </div>
      ),
    },
  ];

  return (
    <div>
      <h6 className="header_text__20 d-flex align-items-center gap-3">
        <button
          onClick={props.toggleContactUploadStep}
          className={` dashboard-button p-0`}
        >
          <img
            src="/images/icons/back-icon.svg"
            width={24}
            height={24}
            alt="Back"
          />
        </button>
        Upload Contacts from Excel
      </h6>

      <div>
        <div className="d-flex align-items-center gap-2">
          <CheckCircleFilled style={{ color: "#DADCE0" }} />
          Download Sample file (Optional)
        </div>
        <div>
          <p className="small_text__14">
            Name and Phone numbers ( with country code, e.g; +91) are mandatory
            fields
          </p>
          <div className="d-flex justify-content-end mt-2">
            <Button
              type={"primary"}
              onClick={() =>
                handleDownloadOnClick(props.downloadFile, "template")
              }
            >
              Download Template
            </Button>
          </div>
        </div>
      </div>
      <div className="mt-3">
        <DragAndDropFileComponent
          uploadFile={props.handleUploadDocuments}
          name="file"
          classes="bulk-upload_drag-n-drop-wrapper position-absolute"
          fileName={getValue(props, `uploadedFileInfofilename`, "")}
          setImportTedFile={props.setImportTedFile}
          isLoading={props.uploadLoading}
        />
        {getValue(props, "request.contactUploadId", "") && (
          <div className="d-flex justify-content-center mt-4">
            <Tag icon={<CheckCircleOutlined />} color="success">
              Uploaded successfully
            </Tag>
          </div>
        )}
      </div>
      {/* <div className="mt-3">
        <Table
          size="middle"
          bordered
          columns={columns}
          dataSource={props.allList}
          rowKey="id"
          rowSelection={{
            type: props.selectionType,
            ...props.rowSelection,
          }}
          pagination={{
            current: props.page_no1,
            pageSize: props.limit1,
            total: props.totalCount1,
            showSizeChanger: true,
            pageSizeOptions: [
              "10",
              "20",
              "30",
              "40",
              "50",
              "60",
              "70",
              "80",
              "90",
              "100",
              "150",
              "200",
              "250",
            ],
            onChange: (page_no, pageSize) => {
              props.handleChangePagination1(page_no, pageSize);
            },
            showTotal: (total, range) =>
              `${range[0]}-${range[1]} of ${total} items`,
          }}
        />
      </div> */}

      {/* <Collapse
        accordion
        size="large"
        items={items}
        defaultActiveKey="1"
        className="home-accordion mt-4"
        style={{ backgroundColor: "#fff" }}
      /> */}
    </div>
  );
};

export default BroadcastContactsUpload;
