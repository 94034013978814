import { useEffect, useMemo, useRef, useState } from "react";
import React from "react";
import { getValue } from "@utils/lodash";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { QueryRequestHelper } from "common/query-request-helper";
import SimpleReactValidator from "simple-react-validator";
import { toast } from "sonner";
import { handleNavigateURL } from "common/url-navigate";
import DeleteModal from "@components/Dialogs/Modals/deleteModal";
import {
  allPipelines,
  getSpecificPipeline,
  getSpecificPipelineColumns,
} from "@services/pipeline.service";
import {
  removeDuplicates,
  removeNullOrUndefinedProperties,
} from "common/text-helpers";
import {
  contactsBulkUpdate,
  deleteContact,
  getSpecificContact,
  updateContact,
} from "@services/contacts.service";
import "./contact.scss";
import HomeHeader from "@components/common/Header/HomeHeader/Header";
import { useStateContext } from "context/profileProvider";
import {
  intialPipelineState,
  sortPipelineFieldList,
} from "@components/Pages/Pipeline/helpers/create-pipeline-helper";
import CommonBulkEditPopup from "@components/Pages/Pipeline/EditPopup";
import CommonAddPipeline from "@components/Pages/Pipeline/AddPipeline";
import CommonAddSubPipeline from "@components/Pages/Pipeline/AddSubPipeline";
import CommonAddFilterView from "@components/Pages/Pipeline/AddFilterViews";
import EditFieldsPopup from "@components/Pages/Pipeline/EditFieldsPopup";
import PipelineSubHeader from "@components/Pages/Pipeline/SubHeader";
import CustomDataTable from "@components/custom/Datatable";
import { commonCustomFilter } from "@services/smart-filter.service";
import {
  FormRequestHelper,
  appendObjectValuesToArray,
  removeDuplicatesById,
} from "@components/helpers/request-helper";
import CallRecordingPopup from "./components/recording-popup";
import ModulePipelineSubheader from "@components/Pages/Pipeline/common/subheader";
import Pagination from "@components/Pages/Pipeline/common/pagination";
import ShowSubPipeline from "@components/Pages/Pipeline/ShowSubPipeline";
import _ from "lodash";
import { getAllModuleFields } from "@services/module-fields.service";
import { listAllModules } from "@services/modules.service";
import { Tour, TourProps } from "antd";
import useDynamicTitle from "@context/useDynamicTitle";
import MainLayout from "@layouts/HomeLayout/NewLayout";

// import Filter from "@components/Filter/filter";
// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface IContactsProps {}

const Contacts: React.FunctionComponent<IContactsProps> = (props: any) => {
  const params = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const urlSearchParams = new URLSearchParams(window.location.search);
  const UrlParams = Object.fromEntries(urlSearchParams.entries());

  const { userId, getUserIDCallback, handleChangeModule, allModules } =
    useStateContext();

  const selectedModuleId = async () => {
    let resp = await listAllModules("");
    let moduleInfo = getValue(resp, `data`, []).find(
      (item: object) => getValue(item, `api_name`, "") === "contacts"
    );
    return getValue(moduleInfo, `id`, "");
  };

  /* -------------------------------------------------------------------------- */
  /*                               Validation Section                           */
  /* -------------------------------------------------------------------------- */
  const simpleValidator1 = useRef(new SimpleReactValidator());
  const [, forceUpdate1] = useState(0);

  /* -------------------------------------------------------------------------- */
  /*                               UseEffect Section                            */
  /* -------------------------------------------------------------------------- */
  useEffect(() => {
    if (Object.keys(UrlParams).length === 0) {
      getUserIDCallback();
      getAllPipelines();
      handleChangeModule();
    }
  }, [location.key]);

  useEffect(() => {
    if (Object.keys(UrlParams).length > 0) {
      getUserIDCallback();
      if (getValue(UrlParams, `search`, "")) {
        setSearchInput(UrlParams.search);
        setShowSearchTextbox(true);
      }
      if (UrlParams.page_no) {
        setPage_no(parseInt(UrlParams.page_no));
      }
      if (UrlParams.limit) {
        setLimit(parseInt(UrlParams.limit));
      }
      getAllPipelines();
    }
  }, [
    window.location.href,
    getValue(localStorage, `sf:contacts`, ""),
    getValue(localStorage, `s:contacts`, ""),
  ]);

  /* -------------------------------------------------------------------------- */
  /*                               API Section                                  */
  /* -------------------------------------------------------------------------- */
  const [staticFieldRequest, setStaticFieldRequest] = React.useState({
    pipeline_id: "",
    stage: "",
  });
  const [stageList, setStageList] = React.useState<any>([]);
  const [selectedIds, setSelectedIds] = useState([]);
  /**
   *
   * Get all Module pipelines
   * @requires
   *
   */

  const [isLoading, setIsLoading] = useState(false);
  const [options, setOptions] = useState([]);
  const getAllPipelines = async () => {
    try {
      setIsLoading(true);
      let payload = {};
      let queryRequest = QueryRequestHelper(payload);
      let resp = await allPipelines(await selectedModuleId(), queryRequest);
      if (resp) {
        let list =
          getValue(resp, `data.length`, 0) > 0
            ? getValue(resp, `data`, []).map((item: object) => ({
                ...item,
                value: getValue(item, `api_name`, ""),
                label: getValue(item, `label`, ""),
              }))
            : [];
        //**----------------------- Pipeline Section ---------------- */
        setOptions(list);
        //**----------------------- Status & filterview section ---------------- */
        if (getValue(UrlParams, `pipeline`, "")) {
          setStaticFieldRequest((prevStaticFieldRequest) => ({
            ...prevStaticFieldRequest,
            pipeline_id: getValue(UrlParams, "pipeline", ""),
          }));
          getData(getValue(UrlParams, `pipeline`, ""));
          getListData(getValue(UrlParams, `pipeline`, ""), true);
        } else {
          setStaticFieldRequest((prevStaticFieldRequest) => ({
            ...prevStaticFieldRequest,
            pipeline_id: getValue(list, `[${0}].id`, ""),
          }));
          getData(getValue(list, `[${0}].id`, {}));
          getListData(getValue(list, `[${0}].id`, {}), true);
        }
        setIsLoading(false);
      } else {
        setIsLoading(false);
        setListLoading(false);
      }
    } catch (error) {
      setIsLoading(false);
      setListLoading(false);
    }
  };

  /**
   *
   * Get selected pipeline list
   * @requires id
   *
   */
  useEffect(() => {
    if (!getValue(props, `permissions`, []).includes("view_all")) {
      navigate(handleNavigateURL("filter_owner_id", userId));
    }
  }, [getValue(props, `permissions`, [])]);

  const [listLoading, setListLoading] = useState(true);
  const [list, setList] = useState([]);
  const getListData = async (id: string, status: boolean) => {
    if (typeof id === "string") {
      try {
        let payload = {
          pipeline_id: id,
          module_id: await selectedModuleId(),
          filter_owner_id:
            getValue(props, `permissions`, []).includes("view_all") &&
            !getValue(UrlParams, `filter_owner_id`, "")
              ? ""
              : getValue(UrlParams, `filter_owner_id`, "")
              ? getValue(UrlParams, `filter_owner_id`, "")
              : userId,
          filter_pipeline_stage_id: getValue(
            UrlParams,
            `filter_pipeline_stage_id`,
            ""
          ),
          filter_closing_date: getValue(UrlParams, `filter_closing_date`, ""),
          filter_created_at: getValue(UrlParams, `filter_created_at`, ""),
          page_size: getValue(UrlParams, `limit`, "")
            ? getValue(UrlParams, `limit`, "")
            : limit,
          page_no: getValue(UrlParams, `page_no`, "")
            ? getValue(UrlParams, `page_no`, "")
            : page_no,
          search: getValue(UrlParams, `search`, ""),
        };
        let queryRequest = QueryRequestHelper(payload);
        setListLoading(status);
        let obj = {
          has_advance_filter: getValue(localStorage, `sf:contacts`, "")
            ? true
            : false,
          has_group_by: false,
          filter_groups: getValue(localStorage, `sf:contacts`, "")
            ? JSON.parse(getValue(localStorage, `sf:contacts`, ""))
            : null,
          sort_by: getValue(localStorage, `s:contacts`, "")
            ? {
                ...JSON.parse(getValue(localStorage, `s:contacts`, "")),
                custom: false,
              }
            : {
                custom: false,
                field: "created_at",
                value: "desc",
              },
        };
        let resp = await commonCustomFilter(
          "contacts",
          queryRequest,
          removeNullOrUndefinedProperties(obj)
        );
        if (resp) {
          setList(
            getValue(resp, `data.data.contacts`, []).map((item: object) => ({
              ...item,
              stage: getValue(item, `stage`, ""),
            }))
          );
          setTotalCount(getValue(resp, `data.data.pagination.total`, 0));
          setListLoading(false);
        } else {
          setList([]);
          setListLoading(false);
        }
      } catch (error) {
        setList([]);
        setListLoading(false);
      }
    }
  };

  /**
   *
   * Create Sale Pipeline
   * @requires body
   *
   */

  const handleChangePipeline = (e: any) => {
    delete UrlParams.pipeline;
    let payload = {
      ...UrlParams,
    };
    let queryRequest = QueryRequestHelper(payload);
    navigate(`${window.location.pathname}?${queryRequest}`);
  };
  const handleClickPipeline = (option: any) => {
    delete UrlParams.filter_pipeline_stage_id;
    let payload = {
      ...UrlParams,
      pipeline: getValue(option, `id`, ""),
    };
    let queryRequest = QueryRequestHelper(payload);
    navigate(`${window.location.pathname}?${queryRequest}`);
  };

  /* -------------------------------------------------------------------------- */
  /*                     Helper Functions Section                               */
  /* -------------------------------------------------------------------------- */

  const findSelectedPipeline = (id: string) => {
    let list =
      getValue(options, `length`, 0) > 0
        ? options.filter((item: object) => getValue(item, `id`, "") === id)
        : [];
    return getValue(list, `length`, 0) > 0 ? getValue(list, `[${0}]`, "") : {};
  };
  const getSelectedPipeline = useMemo(
    () => findSelectedPipeline(getValue(staticFieldRequest, `pipeline_id`, "")),
    [getValue(staticFieldRequest, `pipeline_id`, "")]
  );

  /* -------------------------------------------------------------------------- */
  /*                     Other Functions Section                                */
  /* -------------------------------------------------------------------------- */

  const [activeTab, setActiveTab] = useState("1");
  const toggle = (tab: string) => {
    if (activeTab !== tab) {
      setActiveTab(tab);
    }
  };
  const filterNavigationArray: any = [];
  const subMenuArray: any = [];

  /* -------------------------------------------------------------------------- */
  /*                                  Edit section                              */
  /* -------------------------------------------------------------------------- */
  const [isOpenEditPopup, setIsOpenEditPopup] = useState(false);
  const openEditPopup = () => {
    setIsOpenEditPopup(!isOpenEditPopup);
  };
  const [selectedEditOption, setSelectedEditOption] = useState("");
  const [editRequest, setEditRequest] = useState<any>({
    stage: "",
    owner_id: "",
  });
  const [editLoading, setEditLoading] = useState(false);
  const handleOpenFilter = (value: string) => {
    setSelectedEditOption(value);
    openEditPopup();
  };
  const handleSubmitEdit = async () => {
    try {
      setEditLoading(true);
      let resp = await contactsBulkUpdate({
        ids: selectedIds,
        data: {
          [selectedEditOption === "stage_id"
            ? "pipeline_stage_id"
            : selectedEditOption]: editRequest[selectedEditOption],
        },
      });
      if (resp) {
        toast.success("Updated Succssfully");
        openEditPopup();
        getListData(getValue(staticFieldRequest, `pipeline_id`, ""), false);
        setSelectedEditOption("");
        setEditRequest({
          ...editRequest,
          stage: "",
          owner_id: "",
        });
        setSelectedIds([]);
        setEditLoading(false);
      } else {
        setEditLoading(false);
      }
    } catch (error) {
      setEditLoading(false);
    }
  };

  /* ----------------------------- Delete View Section  ------------------------- */
  const [deleteId1, setDeleteId1] = useState("");
  const [deleteTableValue, setDeleteTableValue] = useState("");
  const [isOpen1, setIsOpen1] = useState(false);
  const handleModal1 = () => {
    setIsOpen1(!isOpen1);
  };
  const handleDeleteTable = (id: string) => {
    setDeleteId1(id);
    handleModal1();
  };
  const handleDeleteTableRow = async () => {
    try {
      let resp = await deleteContact(deleteId1);
      if (resp) {
        toast.success("Deleted Successfully");
        //delete
        getListData(getValue(staticFieldRequest, `pipeline_id`, ""), false);
        setSelectedIds([]);
        handleModal1();
      }
    } catch (error) {}
  };
  /* -------------------------------------------------------------------------- */
  /*                          Create Pipline Section                            */
  /* -------------------------------------------------------------------------- */
  const simpleValidator = useRef(new SimpleReactValidator());
  const [, forceUpdate] = useState(0);
  const [isOpenPipeline, setIsOpenPipeline] = useState(false);
  const togglePipeline = () => {
    setIsOpenPipeline(!isOpenPipeline);
  };
  const [pipelineRequest, setPipelineRequest] =
    useState<any>(intialPipelineState);
  /* -------------------------------------------------------------------------- */
  /*                       Create SubPipline Section                            */
  /* -------------------------------------------------------------------------- */

  const [isOpenSubPipeline, setIsOpenSubPipeline] = useState(false);
  const toggleOpenSubpipeline = () => {
    setIsOpenSubPipeline(!isOpenSubPipeline);
  };

  const [selectedFields, setSelectedFields] = useState([]);
  const [optionLoading, setOptionLoading] = useState(false);
  const [selectedOption, setSelectedOption] = useState({});
  const [headers, setHeaders] = useState<any>([]);
  const getData = async (pipeline_id: string) => {
    if (typeof pipeline_id === "string") {
      try {
        setOptionLoading(true);
        let resp = await getSpecificPipeline(
          await selectedModuleId(),
          pipeline_id
        );
        if (resp) {
          setSelectedOption(getValue(resp, `data`, {}));
          let list = sortPipelineFieldList(
            getValue(resp, `data.form_fields`, [])
          );
          for (const item of list) {
            if (getValue(item, `input_type`, "") !== "select_multiple") {
              if (
                typeof getValue(item, `default_value`, null) === "string" ||
                typeof getValue(item, `default_value`, null) === "boolean"
              ) {
                item.value = getValue(item, `default_value`, null);
              }
            } else {
              item.value = [getValue(item, `default_value`, null)];
            }
            if (item.api_name === "owner_id") {
              item.value = userId;
            }
          }
          const aDict = list.reduce((dict: any, item: any) => {
            dict[item.name] = item;
            return dict;
          }, {});
          //**----------- Creating Static Fields for table --------------- */
          let selected = [];
          if ((await selectedModuleId()) && pipeline_id) {
            let columnsList = await getSpecificPipelineColumns(
              await selectedModuleId(),
              pipeline_id
            );
            selected =
              getValue(columnsList, `data.length`, 0) > 0
                ? getValue(columnsList, `data`, []).filter((item: object) =>
                    getValue(item, `is_selected`, "")
                  )
                : [];
            setHeaders(getValue(columnsList, `data`, []));
          }
          const ReorderedList: any = [];
          // Add elements from b in the order they appear in a
          for (const name of selected) {
            if (aDict[name]) {
              ReorderedList.push(aDict[name]);
            }
          }
          // Add elements from a that are not in b to the end
          for (const item of list) {
            if (!selected.includes(item.name)) {
              ReorderedList.push(item);
            }
          }
          setSelectedFields(removeDuplicates(ReorderedList));
          let formList =
            getValue(list, `length`, 0) > 0
              ? list.filter((item: object) =>
                  getValue(item, `form_default`, false)
                )
              : [];
          setPipelineRequest({
            ...pipelineRequest,
            fields: removeDuplicatesById(formList),
          });

          //**------------ creating stage object -------------------- */
          let filteredStages = getValue(resp, `data.stages`, []).filter(
            (item: object) => !getValue(item, `archived`, false)
          );
          setStaticFieldRequest((prevStaticFieldRequest) => ({
            ...prevStaticFieldRequest,
            stage: getValue(filteredStages, `[${0}].id`, ""),
          }));
          setStageList(
            filteredStages.map((item: object) => ({
              ...item,
              label: getValue(item, `label`, ""),
              value: getValue(item, `id`, ""),
            }))
          );

          setOptionLoading(false);
        } else {
          setOptionLoading(false);
        }
      } catch (error) {
        setOptionLoading(false);
      }
    }
  };

  /* -------------------------------------------------------------------------- */
  /*                  Hide Body Scroll When Modal Open                          */
  /* -------------------------------------------------------------------------- */
  useEffect(() => {
    if (isOpenSubPipeline) {
      document.body.classList.add("modal-open");
    } else {
      document.body.classList.remove("modal-open");
    }
  }, [isOpenSubPipeline]);

  /* -------------------------------------------------------------------------- */
  /*                       Create Filterview Section                            */
  /* -------------------------------------------------------------------------- */
  const [editFieldsOpen, setEditFieldsOpen] = useState(false);
  const handleEditFieldsModal = () => {
    setEditFieldsOpen(!editFieldsOpen);
  };
  const handleNaviagteView = (name: string, value: string) => {
    if (!value) {
      getAllPipelines();
    }
    navigate(handleNavigateURL(name, value));
  };
  /* -------------------------------------------------------------------------- */
  /*                       Advanced Filterview Sectio                           */
  /* -------------------------------------------------------------------------- */
  const [isOpenFilterView, setIsOpenFilterView] = useState(false);
  const handleOpenFilterView = () => {
    setIsOpenFilterView(!isOpenFilterView);
  };

  /* -------------------------------------------------------------------------- */
  /*                               Search section                               */
  /* -------------------------------------------------------------------------- */
  const [showSearchTextbox, setShowSearchTextbox] = useState(false);
  const [search, setSearchInput] = useState<any>("");

  const toggleSearch = () => {
    setShowSearchTextbox((prevState) => !prevState);
  };
  const toggleSearchClose = () => {
    setSearchInput("");
    toggleSearch();
    if (UrlParams.search) {
      delete UrlParams.search;
      let payload: any = { ...UrlParams };
      let queryRequest = QueryRequestHelper(payload);
      navigate(`${window.location.pathname}?${queryRequest}`);
      getData(getValue(staticFieldRequest, `pipeline_id`, ""));
    }
  };
  const handleChangeSearch = async (e: any) => {
    const regex = /^[a-zA-Z0-9]*$/;
    if (regex.test(e.target.value)) {
      setSearchInput(e.target.value);
      let payload: any = {
        ...UrlParams,
        search: e.target.value,
      };
      let queryRequest = QueryRequestHelper(payload);
      navigate(`${window.location.pathname}?${queryRequest}`);
    }
  };

  /* -------------------------------------------------------------------------- */
  /*                               Pagination section                           */
  /* -------------------------------------------------------------------------- */

  const [totalCount, setTotalCount] = useState(0);
  const [limit, setLimit] = useState(10);
  const [page_no, setPage_no] = useState(1);

  const handleChangePagination = (page_no: string) => {
    let payload = {
      ...UrlParams,
      page_no: page_no,
    };
    let queryRequest = QueryRequestHelper(payload);
    navigate(`${window.location.pathname}?${queryRequest}`);
  };
  const handleChangeLimit = (limit: string) => {
    let payload: any = {
      ...UrlParams,
      limit: limit,
      page_no: "1",
    };
    let queryRequest = QueryRequestHelper(payload);
    navigate(`${window.location.pathname}?${queryRequest}`);
  };

  /* -------------------------------------------------------------------------- */
  /*                          Calls Records Section                             */
  /* -------------------------------------------------------------------------- */
  const [callsRecordings, setCallRecordings] = useState([]);
  const [openRecordings, setOpenRecordings] = useState(false);
  const handleCalls = () => {
    setOpenRecordings(!openRecordings);
  };
  const handleOpenCallRecordings = async () => {
    if (selectedIds[0]) {
      try {
        let resp = await getSpecificContact(selectedIds[0]);
        if (resp) {
          if (getValue(resp, `data.calls.length`, 0) > 0) {
            setCallRecordings(getValue(resp, `data.calls`, []));
            handleCalls();
          } else {
            toast.error("No calls available");
          }
        }
      } catch (error) {}
    }
  };

  /* -------------------------------------------------------------------------- */
  /*                                 Module Info                                */
  /* -------------------------------------------------------------------------- */
  const findModuleType = (id: string) => {
    let info =
      getValue(allModules, `length`, 0) > 0
        ? allModules.find((item: object) => getValue(item, `id`, "") == id)
        : [];
    return info;
  };
  const moduleInfo = useMemo(
    () => findModuleType(getValue(selectedOption, `module_id`, "")),
    [selectedOption, getValue(allModules, `length`, 0) > 0]
  );

  const [selectedRowId, setSelectdRowId] = useState(null);

  /* -------------------------------------------------------------------------- */
  /*                              Preview Section                               */
  /* -------------------------------------------------------------------------- */

  const [data, setData] = React.useState<any>({});
  const [previewId, setPreviewId] = useState("");
  const [previewLoading, setIsPreviewLoading] = useState(false);
  const [previewStageList, setPreviewStageList] = React.useState<any>([]);
  const [previewFields, setPreviewFields] = React.useState([]);
  const [previewDirtyFields, setPreviewDirtyFields] = React.useState([]);
  const [previewPopupFields, setPreviewPopupFields] = React.useState([]);
  const [previewDirtyPopupFields, setPreviewDirtyPopupFields] = React.useState(
    []
  );
  const [isOpenShowSubPipeline, setIsOpenShowSubPipeline] = useState(false);
  const toggleOpenShowSubpipeline = () => {
    setIsOpenShowSubPipeline(!isOpenShowSubPipeline);
  };
  const handlePreviewPopup = (id: string) => {
    getContactInfo(id);
    setPreviewId(id);
    toggleOpenShowSubpipeline();
  };

  React.useEffect(() => {
    getContactInfo(previewId);
  }, [previewId]);

  const getContactInfo = async (id: string) => {
    if (id) {
      try {
        setIsPreviewLoading(previewLoading);
        let response = await getSpecificContact(id);
        if (response) {
          let resp = await getSpecificPipeline(
            await selectedModuleId(),
            getValue(response, `data.pipeline_id`, "")
          );
          setPreviewStageList(
            getValue(resp, `data.stages`, []).map((item: object) => ({
              ...item,
              label: getValue(item, `label`, ""),
              value: getValue(item, `api_name`, ""),
            }))
          );

          let fields =
            getValue(resp, `data.form_fields.length`, 0) > 0
              ? getValue(resp, `data.form_fields`, []).map((item: object) => ({
                  ...getValue(item, `module_field`, {}),
                  name: getValue(item, `name`, ""),
                  quick_add: getValue(item, `quick_add`, ""),
                  required: getValue(item, `required`, ""),
                  seq_num: getValue(item, `seq_num`, ""),
                }))
              : [];

          // ---------------- Appending Fields Data ----------------- //
          let array: any = appendObjectValuesToArray(
            fields,
            getValue(response, `data`, {})
          );

          let fieldReq =
            getValue(array, `length`, 0) > 0
              ? array.filter(
                  (item: object) =>
                    getValue(item, `api_name`, "") !== "first_name" &&
                    getValue(item, `api_name`, "") !== "last_name" &&
                    getValue(item, `api_name`, "") !== "email"
                )
              : [];

          let fieldPopReq =
            getValue(array, `length`, 0) > 0
              ? array.filter(
                  (item: object) =>
                    getValue(item, `api_name`, "") === "first_name" ||
                    getValue(item, `api_name`, "") === "last_name" ||
                    getValue(item, `api_name`, "") === "email"
                )
              : [];

          setPreviewFields(fieldReq);
          setPreviewDirtyFields(fieldReq);
          setPreviewPopupFields(fieldPopReq);
          setPreviewDirtyPopupFields(fieldPopReq);

          // ---------------- Appending State Data ----------------- //

          setData(getValue(response, `data`, {}));

          let leftReq = {
            first_name: getValue(response, `data.first_name`, ""),
            last_name: getValue(response, `data.last_name`, ""),
            email: getValue(response, `data.email`, ""),
            stage: getValue(response, `data.pipeline_stage.id`, ""),
          };

          setLeftRequest({
            ...leftRequest,
            ...leftReq,
          });
          setDirtyLeftRequest({
            ...dirtyLeftRequest,
            ...leftReq,
          });

          setIsPreviewLoading(false);
        } else {
          setIsPreviewLoading(false);
        }
      } catch (error) {
        setIsPreviewLoading(false);
      }
    }
  };

  /* -------------------------------------------------------------------------- */
  /*                               Left Section                                 */
  /* -------------------------------------------------------------------------- */

  const [leftRequest, setLeftRequest] = React.useState({
    first_name: "",
    last_name: "",
    email: "",
    stage: "",
  });
  const [dirtyLeftRequest, setDirtyLeftRequest] = React.useState({
    first_name: "",
    last_name: "",
    email: "",
    stage: "",
  });

  let checkLeftRequestChanges = _.isEqual(leftRequest, dirtyLeftRequest);
  let checkfieldRequestChanges = _.isEqual(previewFields, previewDirtyFields);

  /* -------------------------------------------------------------------------- */
  /*                               Footer Section                               */
  /* -------------------------------------------------------------------------- */
  const handleReset = async () => {
    setLeftRequest(dirtyLeftRequest);
    setPreviewFields(previewDirtyFields);
    setDuplicateAPINames([]);
  };

  const handleCancel = () => {
    handleReset();
  };

  const [submitLoading, setSubmitLoading] = React.useState(false);
  const handleSubmit = async () => {
    try {
      let payload: any = {
        first_name: getValue(leftRequest, `first_name`, ""),
        last_name: getValue(leftRequest, `last_name`, ""),
        email: getValue(leftRequest, `email`, ""),
        pipeline_stage_id: getValue(leftRequest, `stage`, ""),
      };
      let fieldReq = JSON.parse(JSON.stringify(previewFields));
      let payloadNew = FormRequestHelper(fieldReq);
      let requestPayload: any = { ...payload, ...payloadNew };

      setSubmitLoading(true);
      let resp = await updateContact(
        getValue(data, `id`, ""),
        removeNullOrUndefinedProperties(requestPayload)
      );
      if (resp) {
        toast.success("Updated successfully");
        getContactInfo(previewId);
        setSubmitLoading(false);
      } else {
        setSubmitLoading(false);
      }
    } catch (error) {
      setSubmitLoading(false);
    }
  };

  const [duplicateAPINames, setDuplicateAPINames] = React.useState<any>([]);

  /* -------------------------------------------------------------------------- */
  /*                               Add Task Section                               */
  /* -------------------------------------------------------------------------- */
  const [isTaskPopupVisibile, setTaskPopupVisisbility] = useState(false);
  const toggleOpenTask = () => {
    setTaskPopupVisisbility(!isTaskPopupVisibile);
  };

  const handleTaskPopup = (id: string) => {
    setPreviewId(id);
    toggleOpenTask();
  };

  const [form, setForm] = React.useState([]);
  const [formStageList, setFormStageList] = React.useState<any>([]);
  const [taskFields, setTaskFields] = useState([]);
  const [formLoading, setFormLoading] = React.useState(false);
  const getForm = async (module: string) => {
    setAssociationForm([]);
    if (module !== "tasks" && module !== "meetings" && module !== "calls") {
      try {
        let payload = {
          module_name: module,
        };
        let queryRequest = QueryRequestHelper(payload);
        setFormLoading(true);
        let findId =
          getValue(allModules, `length`, 0) > 0
            ? allModules.find(
                (item: object) =>
                  getValue(item, `api_name`, "") ===
                  (module === "sales" ? "deals" : module)
              )
            : [];
        let resp = await allPipelines(getValue(findId, `id`, ""), queryRequest);
        if (resp) {
          getAssociationPipelineInfo(
            getValue(resp, `data[${0}].id`, ""),
            module
          );
          setFormStageList(
            getValue(resp, `data[${0}].stages`, []).map((item: object) => ({
              ...item,
              label: getValue(item, `label`, ""),
              value: getValue(item, `api_name`, ""),
            }))
          );
          setFormLoading(false);
        } else {
          setFormLoading(false);
        }
      } catch (error) {
        setFormLoading(false);
      }
    } else {
      try {
        let code =
          getValue(allModules, `length`, 0) > 0
            ? allModules.find(
                (item: object) => getValue(item, `api_name`, "") == module
              )
            : [];
        let resp = await getAllModuleFields(getValue(code, `id`, ""));
        if (resp) {
          let fields =
            getValue(resp, `data.length`, 0) > 0
              ? getValue(resp, `data`, []).map((item: object) => ({
                  ...getValue(item, `module_field`, {}),
                  name: getValue(item, `module_field.api_name`, ""),
                  quick_add: getValue(item, `quick_add`, ""),
                  required: getValue(item, `required`, ""),
                  seq_num: getValue(item, `seq_num`, ""),
                }))
              : [];
          for (const item of fields) {
            if (item.api_name === "owner_id") {
              item.value = userId;
            }
          }
          setAssociationForm(fields);
        }
      } catch (error) {}
    }
  };

  const getAssociationPipelineInfo = async (id: string, module: string) => {
    if (id) {
      try {
        let resp = await getSpecificPipeline(await selectedModuleId(), id);
        if (resp) {
          let fields =
            getValue(resp, `data.form_fields.length`, 0) > 0
              ? getValue(resp, `data.form_fields`, []).map((item: object) => ({
                  ...getValue(item, `module_field`, {}),
                  name: getValue(item, `name`, ""),
                  quick_add: getValue(item, `quick_add`, ""),
                  required: getValue(item, `required`, ""),
                  seq_num: getValue(item, `seq_num`, ""),
                }))
              : [];
          for (const item of fields) {
            if (item.api_name === "owner_id") {
              item.value = userId;
            }
          }
          setStaticFieldRequest((prevStaticFieldRequest) => ({
            ...prevStaticFieldRequest,
            stage: getValue(resp, `data.stages[${0}].id`, ""),
            pipeline_id: id,
          }));
          setAssociationForm(fields);
          setFormLoading(false);
        } else {
          setFormLoading(false);
        }
      } catch (error) {}
    } else {
      setForm([]);
    }
  };

  const [associationForm, setAssociationForm] = React.useState([]);

  /* -------------------------------------------------------------------------- */
  /*                               Add Note Section                               */
  /* -------------------------------------------------------------------------- */
  const [isNotesPopupVisibile, setNotesPopupVisisbility] = useState(false);
  const toggleOpenNotes = () => {
    setNotesPopupVisisbility(!isNotesPopupVisibile);
  };

  const handleNotesPopup = (id: string) => {
    setPreviewId(id);
    toggleOpenNotes();
  };
  useDynamicTitle(
    getValue(moduleInfo, `linkText`, "")
      ? getValue(moduleInfo, `linkText`, "")
      : "Contacts"
  );
  /* -------------------------------------------------------------------------- */
  /*                              Tour Section                               */
  /* -------------------------------------------------------------------------- */
  const tourRef1 = useRef(null);
  const tourRef2 = useRef(null);
  const tourRef3 = useRef(null);
  const tourRef4 = useRef(null);
  const tourRef5 = useRef(null);
  const tourRef6 = useRef(null);

  const [openTour, setOpenTour] = useState<boolean>(false);

  const steps: TourProps["steps"] = [
    {
      title: "Modules",
      description: `At the higher level, data in Appzo is organised into modules. You can use each module to store specific type of records. \n\n For instance: Contacts is where you can store information about your customers and prospects. Companies are the organisations where your contacts work for. You can switch between these different modules from this bar on the left side.`,
      cover: (
        <img
          alt="tour.png"
          src="/tooltip/crm/crm-navbar.png"
          style={{ width: "150px" }}
        />
      ),
      target: () => tourRef1.current,
    },
    {
      title: "Team Pipelines",
      description: `Team Pipelines are the core of Appzo and this is where you'll be spending most of your time with. Team Pipelines help you visualise any work you do for your customer into different stages. You can create different team pipelines for different operations you deal with customers like Sales, Customer Support, etc.`,
      target: () => tourRef2.current,
    },

    {
      title: "Pipeline Filters",
      // description:
      //   "This is the Pipeline View in Appzo. This gives you a visual overview of the pipelines your team is working on, categorized by stages.",
      target: () => tourRef3.current,
    },
    {
      title: "Customize Filter Properties",
      description:
        "You can use the 'Advance Filters' option to manage the properties you wish to see on each pipeline card in the pipeline view.",
      cover: (
        <img
          alt="tour.png"
          src="/tooltip/crm/crm-advance-filters.png"
          style={{ width: "150px" }}
        />
      ),
      target: () => tourRef4.current,
    },
    {
      title: "Pipeline View",
      description:
        "This is the Pipeline View in Appzo. This gives you a visual overview of the pipelines your team is working on, categorized by stages.",
      target: () => tourRef5.current,
    },
    {
      title: "Data",
      // description:
      //   "This is the Pipeline View in Appzo. This gives you a visual overview of the pipelines your team is working on, categorized by stages.",
      target: () => tourRef6.current,
    },
  ];
  return (
    <MainLayout {...props} isLoading={listLoading}>
      <Tour
        open={openTour}
        onClose={() => setOpenTour(false)}
        mask={true}
        steps={steps}
        type="primary"
      />
      {/* <HomeHeader setOpenTour={setOpenTour} tourRef1={tourRef1} /> */}
      <PipelineSubHeader
        subHeaderListTitle={getValue(moduleInfo, `linkText`, "")}
        isRightArrowVisible={true}
        isSubmenuListAvailable={true}
        subMenuArray={subMenuArray}
        options={options}
        selectedPipeline={getSelectedPipeline}
        handleChangePipeline={handleChangePipeline}
        handleClickPipeline={handleClickPipeline}
        hideClose={!getValue(UrlParams, `pipeline`, "") ? true : false}
        isLoading={optionLoading}
        pipeline={false}
        handleOpenPopup={togglePipeline}
        moduleInfo={moduleInfo}
        tourRef2={tourRef2}
      />
      <div className="d-flex ">
        <div className={`manageFieldRight w-100`}>
          <ModulePipelineSubheader
            //navviews section
            handleNaviagteView={handleNaviagteView}
            // filter section
            setIsOpenSubPipeline={setIsOpenSubPipeline}
            filterNavigationArray={filterNavigationArray}
            moduleInfo={moduleInfo}
            stageList={stageList}
            isFilterVisibile={true}
            isStatusVisible={true}
            //search section
            toggleSearch={toggleSearch}
            toggleSearchClose={toggleSearchClose}
            handleChangeSearch={handleChangeSearch}
            searchInput={search}
            showSearchTextbox={showSearchTextbox}
            //edit table section
            openEditPopup={openEditPopup}
            setSelectedIds={setSelectedIds}
            handleOpenFilter={handleOpenFilter}
            handleDeleteTable={handleDeleteTable}
            handleOpenCallRecordings={handleOpenCallRecordings}
            //others section
            getListData={getListData}
            getAllPipelines={getAllPipelines}
            handleEditFieldsModal={handleEditFieldsModal}
            handleOpenFilterView={handleOpenFilterView}
            options={options}
            getSelectedPipeline={getSelectedPipeline}
            showAdvancedFilter={true}
            importLogs={true}
            hideCloseDate={true}
            module={"contacts"}
            selectedIds={selectedIds}
            callsRecording={true}
            plural_label={"Contacts"}
            //sort section
            isSort={false}
            fields={selectedFields}
            tourRef3={tourRef3}
            tourRef4={tourRef4}
            permissions={getValue(props, `permissions`, [])}
          />
          <CustomDataTable
            headers={headers}
            data={list}
            setHeaders={setHeaders}
            singular_label={getValue(selectedOption, `label`, "")}
            selectedIds={selectedIds}
            setSelectedIds={setSelectedIds}
            module="contacts"
            isLoading={listLoading}
            add
            stageList={stageList}
            selectedPipeline={getSelectedPipeline}
            getListData={getListData}
            handlePreviewPopup={handlePreviewPopup}
            handleTaskPopup={handleTaskPopup}
            handleNotesPopup={handleNotesPopup}
            tourRef5={tourRef5}
            tourRef6={tourRef6}
          />
          <Pagination
            totalCount={totalCount}
            limit={limit}
            page_no={page_no}
            handleChangePagination={handleChangePagination}
            handleChangeLimit={handleChangeLimit}
            list={list}
          />
        </div>
        {/* ------------------------- Edit Table Section ------------------------------- */}
        <CommonBulkEditPopup
          isOpen={isOpenEditPopup}
          handleModal={openEditPopup}
          request={editRequest}
          setRequest={setEditRequest}
          stageList={stageList}
          selectedEditOption={selectedEditOption}
          setSelectedEditOption={setSelectedEditOption}
          isLoading={editLoading}
          handleSubmit={handleSubmitEdit}
          permissions={getValue(props, `permissions`, [])}
        />
        <DeleteModal
          isOpen={isOpen1}
          handleModal={handleModal1}
          handleSubmit={handleDeleteTableRow}
          deleteValue={deleteTableValue}
        />
        {/* ------------------------- Creating Pipelines --------------------------------- */}
        <CommonAddPipeline
          isOpen={isOpenPipeline}
          toggle={togglePipeline}
          title={"Create Contact Pipeline"}
          simpleValidator={simpleValidator}
          forceUpdate={forceUpdate}
          request={pipelineRequest}
          setRequest={setPipelineRequest}
          module_id={getValue(selectedOption, `module_id`, "")}
          module={"contacts"}
        />
        {/* ------------------------- Creating Sub Pipelines ------------------------------ */}
        <CommonAddSubPipeline
          isOpen={isOpenSubPipeline}
          toggle={toggleOpenSubpipeline}
          singular_label={getValue(selectedOption, `label`, "")}
          getAllPipelines={getAllPipelines}
          stageList={stageList}
          fields={selectedFields}
          setFields={setSelectedFields}
          simpleValidator={simpleValidator1}
          forceUpdate={forceUpdate1}
          module={"contacts"}
          staticFieldRequest={staticFieldRequest}
          setStaticFieldRequest={setStaticFieldRequest}
          permissions={getValue(props, `permissions`, [])}
        />

        {/* ------------------------- Editing Table Fields ------------------------- */}
        <EditFieldsPopup
          isOpen={editFieldsOpen}
          toggle={handleEditFieldsModal}
          fields={selectedFields}
          setFields={setSelectedFields}
          id={getValue(selectedOption, `id`, "")}
          headers={headers}
          setHeaders={setHeaders}
          getData={getData}
          permissions={getValue(props, `permissions`, [])}
        />
        {/* ------------------------- Call Record Section ------------------------- */}
        <CallRecordingPopup
          isOpen={openRecordings}
          handleModal={handleCalls}
          callsRecordings={callsRecordings}
        />
        {/* ------------------------- Advanced Filter View ------------------------- */}
        {isOpenFilterView && (
          <CommonAddFilterView
            isOpenFilterView={isOpenFilterView}
            module={"contacts"}
            handleOpenFilterView={handleOpenFilterView}
            selectedOption={selectedOption}
            getData={getAllPipelines}
            permissions={["create"]}
          />
        )}

        {/* ------------------------- Preview Module ------------------------------ */}
        <ShowSubPipeline
          isOpen={isOpenShowSubPipeline}
          previewId={previewId}
          setIsOpen={setIsOpenShowSubPipeline}
          toggle={toggleOpenShowSubpipeline}
          headers={headers}
          fields={previewFields}
          setFields={setPreviewFields}
          popupFields={previewPopupFields}
          setPopupFields={setPreviewPopupFields}
          stageList={previewStageList}
          selectedPipeline={getSelectedPipeline}
          data={data}
          getData={getData}
          module={"contacts"}
          request={leftRequest}
          setRequest={setLeftRequest}
          isChanged={!checkLeftRequestChanges || !checkfieldRequestChanges}
          getContactInfo={getContactInfo}
          leftRequest={leftRequest}
          checkLeftRequestChanges={checkLeftRequestChanges}
          checkfieldRequestChanges={checkfieldRequestChanges}
          handleCancel={handleCancel}
          submitLoading={submitLoading}
          handleSubmit={handleSubmit}
          duplicateAPINames={duplicateAPINames}
          setDuplicateAPINames={setDuplicateAPINames}
        />
        {/* <TableAddTask
          isOpen={isTaskPopupVisibile}
          setIsOpen={setTaskPopupVisisbility}
          toggle={toggleOpenTask}
          params={props.params}
          data={data}
          request={leftRequest}
          setRequest={setLeftRequest}
          fields={taskFields}
          setFields={setTaskFields}
          popupFields={previewPopupFields}
          setPopupFields={setPreviewPopupFields}
          getData={getContactInfo}
          module={"contacts"}
          isChanged={!checkLeftRequestChanges || !checkfieldRequestChanges}
          stageList={previewStageList}
          // getForm={props.getForm}
          form={form}
          setForm={setForm}
          associationForm={associationForm}
          setAssociationForm={setAssociationForm}
          setStage={setPreviewStageList}
       
          getForm={getForm}
          formLoading={formLoading}
          formStageList={formStageList}
        />
        <TableAddNote
          isOpen={isNotesPopupVisibile}
          setIsOpen={setNotesPopupVisisbility}
          toggle={toggleOpenNotes}
          form={form}
          setForm={setForm}
          associationForm={associationForm}
          setAssociationForm={setAssociationForm}
          stageList={previewStageList}
          setStage={setPreviewStageList}
          module={"contacts"}
          data={data}
          getData={getContactInfo}
          fields={taskFields}
          setFields={setTaskFields}
          getForm={getForm}
          formLoading={formLoading}
          formStageList={formStageList}
        /> */}
      </div>
    </MainLayout>
  );
};

export default Contacts;
