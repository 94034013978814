// import { getValue } from "@utils/lodash";
// import { Link, useLocation, useParams } from "react-router-dom";
// import CompanyProfileSvgComponent from "assets/svg/company-profile";
// import BrandingSvgComponent from "assets/svg/branding";
// import AppBrandingSvgComponent from "assets/svg/app-branding";
// import ModulesAndFieldsSvgComponent from "assets/svg/modules-fields";
// import EmailSvgComponent from "assets/svg/email";
// import { useStateContext } from "context/profileProvider";
// import DataAdministrationSvgComponent from "@assets/svg/data";
// import ChatSvgComponent from "@assets/svg/chat";
// import BroadcastSvgComponent from "@assets/svg/broadcast";
// import AdsSvgComponent from "@assets/svg/ads";
// import FileSvgComponent from "@assets/svg/file";
// import ChatbotSvgComponent from "@assets/svg/chatbot";
// import SearchSvgComponent from "@assets/svg/search";
// import { useState } from "react";
// import { UserRound, UserRoundCog } from "lucide-react";
// import { usePermissionContext } from "@context/permissionContext";
// import CompanyNavigationList from "./left-menu-tabs";

// export default function CompanyProfileNavigation() {
//   const params = useParams();
//   const location = useLocation();
//   const [searchQuery, setSearchQuery] = useState("");
//   const { tabPermissionList, subTabPermissionList } = usePermissionContext();

//   const currentPath = location.pathname.split("/").pop();
//   let navigationItems = CompanyNavigationList();

//   const filteredNavigation = navigationItems
//     .map((category) => ({
//       ...category,
//       linkArray: category.linkArray.filter((link) =>
//         link.linkTitle.toLowerCase().includes(searchQuery.toLowerCase())
//       ),
//     }))
//     .filter((category) => category.linkArray.length > 0);

//   const renderIcons = (name: any, color: any) => {
//     switch (name) {
//       case "company-profile":
//         return <CompanyProfileSvgComponent color={color} />;
//       case "branding":
//         return <BrandingSvgComponent color={color} />;
//       case "app-branding":
//         return <AppBrandingSvgComponent color={color} />;
//       case "module-and-field":
//         return <ModulesAndFieldsSvgComponent color={color} />;
//       case "roles":
//         return <UserRoundCog color={color} size={20} />;
//       case "users":
//         return <UserRound color={color} size={20} />;
//       case "data-administration-exports":
//         return <DataAdministrationSvgComponent color={color} />;
//       case "config":
//       case "emails":
//         return <EmailSvgComponent color={color} />;
//       case "forms":
//         return <FileSvgComponent color={color} />;
//       case "messages":
//         return <ChatSvgComponent color={color} size={20} />;
//       case "broadcast":
//         return <BroadcastSvgComponent color={color} />;
//       case "apps":
//         return <ChatbotSvgComponent color={color} size={20} />;
//       case "ads":
//         return <AdsSvgComponent color={color} />;
//       default:
//         return <CompanyProfileSvgComponent color={color} />;
//     }
//   };

//   const { orgInfo } = useStateContext();

//   return (
//     <div className="company-profile-details-wrapper__navigation">
//       <div className="contact-search-wrapper top-0 py-2 bg-white mb-2">
//         <div className="message_search pb-2 px-2">
//           <input
//             type="search"
//             name="search-settings"
//             id="search-settings"
//             className="contact-search"
//             value={searchQuery}
//             onChange={(e) => setSearchQuery(e.target.value)}
//             placeholder="Search settings"
//           />
//           <div className="search-icon">
//             <SearchSvgComponent color="#8696a0" size={18} />
//           </div>
//         </div>
//       </div>
//       {filteredNavigation.map((navObj, index) => (
//         <div
//           key={`Company-nav-${index}`}
//           className="company-profile-details-wrapper__navigation-container"
//         >
//           <div className="d-flex align-items-center justify-content-between">
//             <h6 className="company-profile-details-wrapper__navigation-title px-4">
//               {navObj.title}
//             </h6>
//             <img
//               src="/images/icons/arrow-down.svg"
//               alt="arrow-drop"
//               className="cursor-pointer img-fluid px-4"
//             />
//           </div>
//           <ul>
//             {navObj.linkArray.map((linkObj, linkIndex) => (
//               <li
//                 key={`nav-link-${linkIndex}`}
//                 className={
//                   location.pathname.startsWith(getValue(linkObj, "linkUrl", ""))
//                     ? "company-profile-details-wrapper__navigation-list_active mt-1"
//                     : "company-profile-details-wrapper__navigation-list mt-1"
//                 }
//               >
//                 <Link
//                   to={linkObj.linkUrl}
//                   className="company-profile-details-wrapper__navigation-list-link d-flex align-items-center gap"
//                 >
//                   {renderIcons(
//                     getValue(linkObj, "path", ""),
//                     !location.pathname.startsWith(
//                       getValue(linkObj, "linkUrl", "")
//                     )
//                       ? "#414141"
//                       : getValue(orgInfo, "theme", "") || "#D7242E"
//                   )}
//                   <h6 className="company-profile-details-wrapper__navigation-link-title">
//                     {linkObj.linkTitle}
//                   </h6>
//                 </Link>
//               </li>
//             ))}
//           </ul>
//         </div>
//       ))}
//     </div>
//   );
// }

import { Link, useLocation } from "react-router-dom";
import { Menu } from "antd";
import { useState } from "react";
import SearchSvgComponent from "@assets/svg/search";
import { useStateContext } from "context/profileProvider";
import CompanyNavigationList from "./left-menu-tabs";
import CompanyProfileSvgComponent from "@assets/svg/company-profile";
import AppBrandingSvgComponent from "@assets/svg/app-branding";
import {
  Building2,
  Hotel,
  Mail,
  Megaphone,
  TableOfContents,
  TextCursorInput,
  UserRound,
  UserRoundCog,
  Workflow,
} from "lucide-react";
import DataAdministrationSvgComponent from "@assets/svg/data";
import ChatSvgComponent from "@assets/svg/chat";
import BroadcastSvgComponent from "@assets/svg/broadcast";
import ChatbotSvgComponent from "@assets/svg/chatbot";
import { getValue } from "@utils/lodash";
import { WhatsAppOutlined } from "@ant-design/icons";

export default function CompanyProfileNavigation() {
  const location = useLocation();
  const [searchQuery, setSearchQuery] = useState("");
  const { orgInfo } = useStateContext();

  let navigationItems = CompanyNavigationList();

  const filteredNavigation = navigationItems
    .map((category) => ({
      ...category,
      linkArray: category.linkArray.filter((link) =>
        link.linkTitle.toLowerCase().includes(searchQuery.toLowerCase())
      ),
    }))
    .filter((category) => category.linkArray.length > 0);

  const renderIcons = (name: string, color: string) => {
    switch (name) {
      case "company-profile":
        return <Building2 color={color} size={20} />;
      case "branding":
        return <Hotel color={color} size={20} />;
      case "app-branding":
        return <AppBrandingSvgComponent color={color} />;
      case "module-and-field":
        return <TableOfContents color={color} size={20} />;
      case "roles":
        return <UserRoundCog color={color} size={20} />;
      case "users":
        return <UserRound color={color} size={20} />;
      case "data-administration-exports":
        return <DataAdministrationSvgComponent color={color} />;
      case "emails":
        return <Mail color={color} size={20} />;
      case "forms":
        return <TextCursorInput color={color} size={20} />;
      case "messages":
        return <ChatSvgComponent color={color} size={20} />;
      case "whatsapp":
        return <WhatsAppOutlined style={{ color: color, fontSize: "16px" }} />;
      case "broadcast":
        return <BroadcastSvgComponent color={color} />;
      case "apps":
        return <ChatbotSvgComponent color={color} size={20} />;
      case "ads":
        return <Megaphone color={color} size={20} />;
      case "workflows":
        return <Workflow color={color} size={20} />;
      default:
        return <CompanyProfileSvgComponent color={color} />;
    }
  };

  const menuItems = filteredNavigation.map((category) => ({
    key: category.title,
    label: category.title,
    children: category.linkArray.map((linkObj) => {
      const isActive = location.pathname.startsWith(linkObj.linkUrl);
      const dynamicColor = isActive
        ? getValue(orgInfo, "theme", "#D7242E")
        : "#414141";

      return {
        key: linkObj.linkUrl,
        label: (
          <Link to={linkObj.linkUrl} className=" antmenu-sub-selected">
            {linkObj.linkTitle}
          </Link>
        ),
        icon: renderIcons(linkObj.path, dynamicColor),
      };
    }),
  }));

  const currentOpenKey = filteredNavigation.find((category) =>
    category.linkArray.some((link) => location.pathname.includes(link.linkUrl))
  )?.title as string;

  console.log("current", currentOpenKey);

  return (
    <div className="company-profile-details-wrapper__navigation">
      <div className="contact-search-wrapper top-0 bg-white">
        <div className="message_search pb-2 pt-2 px-2 border-bottom border-top">
          <input
            type="search"
            name="search-settings"
            id="search-settings"
            className="contact-search"
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
            placeholder="Search settings"
          />
          <div className="search-icon">
            <SearchSvgComponent color="#8696a0" size={18} />
          </div>
        </div>
      </div>

      <Menu
        mode="inline"
        defaultSelectedKeys={[location.pathname]}
        // defaultOpenKeys={[
        //   "General",
        //   "Customization",
        //   "Organisation",
        //   "Inbox",
        //   "Marketing",
        //   "Automation",
        //   "Reports",
        // ]}
        defaultOpenKeys={currentOpenKey ? [currentOpenKey] : []}
        items={menuItems}
        className="settings-left-menu"
      />
    </div>
  );
}
