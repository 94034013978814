import "./PendingTaskMainBody.scss";
import { useNavigate } from "react-router-dom";
import MainBodyHeaderFilter from "./common/filter-header";
import _ from "lodash";
import { handleFormValidation } from "@components/custom/validation";
import { getValue } from "@utils/lodash";
import { QueryRequestHelper } from "@common/query-request-helper";
import CommonDetailTasks from "./Tasks";
import CommonDetailNotes from "./Notes";
import CommonDetailActivitiesMeetings from "./Activities/Meetings";
import CommonDetailActivitiesCalls from "./Activities/Calls";
import CommonDetailEmailPage from "./Emails";
import MainBodyNavViews from "./common/nav-views";
import { useState } from "react";

function CommonModulesDetailMiddlePage(props: any) {
  const urlSearchParams = new URLSearchParams(window.location.search);
  const urlParams = Object.fromEntries(urlSearchParams.entries());
  const navigate = useNavigate();
  /* -------------------------------------------------------------------------- */
  /*                               UseState Section                             */
  /* -------------------------------------------------------------------------- */
  const pendingLinkArray = [
    // {
    //   title: "Timelines",
    //   linkUrl: "#",
    // },
    {
      title: "Notes",
      linkUrl: "#",
    },
    {
      title: "Tasks",
      linkUrl: "#",
    },
    {
      title: "Activities",
      linkUrl: "#",
      status: "active",
    },
    {
      title: "Emails",
      linkUrl: "#",
    },
  ];
  const handleTab = (tab: any) => {
    let payload = {
      activeTab: tab,
    };
    let queryRequest = QueryRequestHelper(payload);
    navigate(`${window.location.pathname}?${queryRequest}`);
  };

  const {
    handleOpenNotes,
    handleOpenForm,
    isEventVisibility,
    isCallVisibility,
    setIsEventVisibility,
    setIsCallVisibility,
    handleSendEmail,
  } = props;

  const handleOpenMeeting = () => {
    props.getForms("meetings");
    setIsEventVisibility(!isEventVisibility);
  };
  const handleOpenCall = () => {
    props.getForms("calls");
    setIsCallVisibility(!isCallVisibility);
  };

  const errors = handleFormValidation(props.form);
  const hasErrors = errors.length > 0;

  const [openEditNote, setOpenEditNote] = useState(false);
  const handleEditNote = () => {
    setOpenEditNote(!openEditNote);
  };
  return (
    <div className={"pending-wrapper__main-body"}>
      <MainBodyNavViews
        pendingLinkArray={pendingLinkArray}
        handleTab={handleTab}
        urlParams={urlParams}
        taskCount={getValue(props, `data.tasks.length`, 0)}
      />
      <MainBodyHeaderFilter
        urlParams={urlParams}
        handleOpenForm={handleOpenForm}
        handleSendEmail={handleSendEmail}
        handleOpenNotes={handleOpenNotes}
        isEventOpen={isEventVisibility}
        setIsEventOpen={handleOpenMeeting}
        isCallOpen={isCallVisibility}
        setIsCallOpen={handleOpenCall}
        setOpenEditNote={setOpenEditNote}
        openEditNote={openEditNote}
        handleEditNote={handleEditNote}
        permissions={getValue(props, `permissions`, [])}
      />
      {(!getValue(urlParams, `activeTab`, "") ||
        getValue(urlParams, `activeTab`, "") == 1) && (
        <>
          <CommonDetailNotes
            {...props}
            setOpenEditNote={setOpenEditNote}
            openEditNote={openEditNote}
          />
        </>
      )}
      {(getValue(urlParams, `activeTab`, "") == 2 ||
        getValue(props, `isRollsPopupVisibile`, false)) && (
        <CommonDetailTasks {...props} />
      )}
      {(getValue(props, `isEventVisibility`, false) ||
        (getValue(urlParams, `activeTab`, "") == 3 &&
          (!getValue(urlParams, `activeActivitySubTab`, "") ||
            getValue(urlParams, `activeActivitySubTab`, "") == 1))) && (
        <CommonDetailActivitiesMeetings {...props} />
      )}
      {(getValue(props, `isCallVisibility`, false) ||
        (getValue(urlParams, `activeTab`, "") == 3 &&
          getValue(urlParams, `activeActivitySubTab`, "") == 2)) && (
        <CommonDetailActivitiesCalls {...props} />
      )}
      {(getValue(props, `isPopupVisible`, false) ||
        getValue(urlParams, `activeTab`, "") == 4) && (
        <CommonDetailEmailPage {...props} />
      )}
    </div>
  );
}

export default CommonModulesDetailMiddlePage;
