import { getValue } from "@utils/lodash";
import { useEffect, useRef, useState } from "react";
import { useStateContext } from "@context/profileProvider";
import { listAllPipelines } from "@services/pipeline.service";
import { QueryRequestHelper } from "@common/query-request-helper";
import { getAllAvailableTemplateTags } from "@services/email-config.service";
import { mediaUpload } from "@services/upload.service";
import {
  createWhatsappTemplate,
  getWhatsappTemplate,
  syncWhatsappTemplateStatus,
} from "@services/fb.service";
import {
  convertElementaryArrayToJSONArray,
  removeNullOrUndefinedProperties,
} from "@common/text-helpers";
import { toast } from "sonner";
import AddWhatsappVariable from "../components/template-messages/add-variable-popup";
import TemplateTable from "../components/template-messages/template-list";
import CreateWhatsappTemplatePopup from "../components/template-messages/create-template-popup";
import Loader from "@components/common/Loader/loading";
import { Link, useNavigate, useParams } from "react-router-dom";
import { EditorState, Modifier, convertToRaw } from "draft-js";
import draftToHtmlPuri from "draftjs-to-html";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import parse from "html-to-md";
import convertMarkdownToHtml from "../../../../common/markdown/useMarkdown";
import SearchToolTip from "@components/custom/Dropdown/SearchTooltip";
import Pagination from "@components/Pages/Pipeline/common/pagination";
import SimpleReactValidator from "simple-react-validator";
import ListLoader from "@components/common/Loader";
import { Button, Select, Tag, Tooltip } from "antd";
import TemplateNoDataPage from "@components/common/NoData/template-nodata";
import { PlusOutlined } from "@ant-design/icons";

function TemplateMessages(props: any) {
  const urlSearchParams = new URLSearchParams(window.location.search);
  const UrlParams = Object.fromEntries(urlSearchParams.entries());
  const navigate = useNavigate();
  const params = useParams();
  const intialState = {
    id: "",
    moduleId: "",
    pipelineId: "",
    name: "",
    category: "MARKETING",
    language: "en",
    header: {
      type: "",
      text: "",
      link: "",
    },
    body: "",
    footer: "",
    buttonType: "",
    customTags: [],
    buttons: [],
    status: "",
    isActive: true,
    carousel: [
      {
        header: {
          type: "IMAGE",
          link: "",
        },
        body: "",
        buttons: [{ action_type: "" }, { action_type: "" }],
      },
    ],
  };

  const simpleValidator = useRef(new SimpleReactValidator());
  const [, forceUpdate] = useState(0);
  /* -------------------------------------------------------------------------- */
  /*                               UseState Section                             */
  /* -------------------------------------------------------------------------- */
  const [request, setRequest] = useState<any>(intialState);
  const resetState = () => {
    setEditId("");
    setErrorMessage("");
    setRequest(intialState);
    // setRequest({ ...request });
  };
  /* -------------------------------------------------------------------------- */
  /*                             UseEffect Section                              */
  /* -------------------------------------------------------------------------- */

  const { selectedModuleId } = useStateContext();
  useEffect(() => {
    if (Object.keys(UrlParams).length === 0) {
      getData();
    }
    getTags();
  }, []);

  useEffect(() => {
    if (Object.keys(UrlParams).length > 0) {
      if (UrlParams.page_no) {
        setPage_no(parseInt(UrlParams.page_no));
      }
      if (UrlParams.limit) {
        setLimit(parseInt(UrlParams.limit));
      }
      getData();
    }
  }, [window.location.href]);

  /* -------------------------------------------------------------------------- */
  /*                               API Section                                  */
  /* -------------------------------------------------------------------------- */
  const [isLoading, setIsLoading] = useState(false);
  const [templateList, setTemplateList] = useState<any>([]);
  const getData = async () => {
    try {
      setIsLoading(true);
      let payload = {
        page_size: getValue(UrlParams, `limit`, "")
          ? getValue(UrlParams, `limit`, "")
          : limit,
        page_no: getValue(UrlParams, `page_no`, "")
          ? getValue(UrlParams, `page_no`, "")
          : page_no,
        search: getValue(UrlParams, `search`, ""),
        status: getValue(UrlParams, `status`, ""),
      };
      let queryRequest = QueryRequestHelper(payload);
      let resp = await getWhatsappTemplate(queryRequest);
      if (resp) {
        setTemplateList(getValue(resp, `data.templates`, []));
        setTotalCount(getValue(resp, `data.total`, 0));
        setIsLoading(false);
      } else {
        setIsLoading(false);
      }
    } catch (error) {
      setIsLoading(false);
    }
  };

  const [allTags, setAllTags] = useState([]);
  const [moduleId, setModuleId] = useState("");
  const [pipelineId, setPipelineId] = useState("");
  const [allPipelinesList, setAllPipelineList] = useState([]);
  const getTags = async () => {
    try {
      let allPipelines = await listAllPipelines(
        await selectedModuleId("contacts"),
        ""
      );
      setModuleId(await selectedModuleId("contacts"));
      setPipelineId(getValue(allPipelines, `data.[${0}].id`, ""));
      let list = getValue(allPipelines, `data`, []).map((item: object) => ({
        ...item,
        value: getValue(item, `id`, ""),
        label: getValue(item, `label`, ""),
      }));
      setAllPipelineList(list);
      tagsByPipelineID(getValue(allPipelines, `data.[${0}].id`, ""));
    } catch (error) {}
  };

  const tagsByPipelineID = async (id: string) => {
    setPipelineId(id);
    try {
      let payload = {
        pipelineId: id,
      };
      let queryRequest = QueryRequestHelper(payload);
      let resp = await getAllAvailableTemplateTags(
        await selectedModuleId("contacts"),
        queryRequest
      );
      if (resp) {
        setAllTags(getValue(resp, `data`, []));
      }
    } catch (error) {}
  };

  const handleFileChange = async (e: any) => {
    const file = e.target.files[0];
    let formData = new FormData();
    formData.append("file", file);
    formData.append("filename", getValue(file, `name`, ""));
    formData.append("is_public", "true");
    formData.append("collection", "public-images");
    let resp = await mediaUpload(formData);
    if (resp) {
      setRequest({
        ...request,
        header: {
          ...request.header,
          link: getValue(resp, `data`, {}),
        },
      });
    }

    if (!file) {
      console.error("No file selected");
      return;
    }
  };

  const ConvertedComponent = (data: any) => {
    if (getValue(data, `length`, 0) > 0) {
      const convertedData = data.map((item: any, index: number) => {
        if (item.action_type === "CALL_TO_ACTION") {
          return {
            type: "CALL_TO_ACTION",
            callAction: [
              {
                type: item.type === "url" ? "url" : "call",
                text: item.text,
                url: item.url,
                phone: item.phone,
                index: index,
              },
            ],
          };
        } else if (item.action_type === "URL") {
          return {
            type: "CALL_TO_ACTION",
            callAction: [
              {
                type: item.type === "url" ? "url" : "call",
                text: item.text,
                url: item.url,
                phone: item.phone,
                index: index,
              },
            ],
          };
        } else if (item.action_type === "QUICK_REPLY") {
          return {
            type: "QUICK_REPLY",
            quickReply: [
              {
                text: item.text,
                index: index,
              },
            ],
          };
        }
      });
      return convertedData.filter((item: object) => item);
    } else {
      return [];
    }
  };

  const handleNavigateHome = async () => {
    let payload: any = {
      ...UrlParams,
      page_no: 1,
    };
    let queryRequest = QueryRequestHelper(payload);
    navigate(`${window.location.pathname}?${queryRequest}`);
  };

  /* -------------------------------------------------------------------------- */
  /*                               Onchange section                             */
  /* -------------------------------------------------------------------------- */

  const newfun = (arr1: any, arr2: any, arr3: any) => {
    const arr3Dict = arr3.reduce((acc: any, item: any) => {
      acc[item.name] = item;
      return acc;
    }, {});
    const newResult = arr1
      .map((item: any) => {
        const placeholderName = item.placeholder
          .replace("[[", "")
          .replace("]]", "");

        if (arr3Dict[placeholderName]) {
          const arr2Item = arr2.find(
            (x: any) => x.placeholder === item.placeholder
          );
          const fieldId = arr2Item ? arr2Item.fieldId : "";
          const sample = arr2Item ? arr2Item.sample : "";
          const sampleInfo = getValue(findInfo, `tags`, []).find(
            (item: object) => getValue(item, `module_field_id`, "") === fieldId
          );
          return {
            type: item.type,
            label: arr3Dict[placeholderName].label,
            name: arr3Dict[placeholderName].name,
            sample: sample ? sample : getValue(sampleInfo, `sample`, ""),
            placeholder: item.placeholder,
            fieldId: fieldId
              ? fieldId
              : arr3Dict[placeholderName].fieldId
              ? arr3Dict[placeholderName].fieldId
              : getValue(sampleInfo, `module_field_id`, ""),
          };
        }
        return null;
      })
      .filter((item: any) => item !== null);

    return newResult;
  };

  const handleChangeButton = (value: string) => {
    setRequest({
      ...request,
      buttonType: value,
    });
  };

  const handleAddTypeButton = (name: string, type: String) => {
    const buttonArray = getValue(request, `buttons`, []);
    if (buttonArray.length < 3) {
      if (name === "QUICK_REPLY") {
        let obj = { text: "", action_type: name };
        buttonArray.push(obj);
        setRequest({ ...request });
      } else {
        let obj = {
          action_type: name,
          type: type,
          text: "",
          url: "",
          phone: "",
        };
        buttonArray.push(obj);
        setRequest({ ...request });
      }
    } else {
      toast.error("you have reached maximum limits");
    }
  };

  const getTypeDisableStatus = (name: string) => {
    const buttonType = name === "QUICK_REPLY" ? "quickReply" : "callAction";
    const buttonArray = getValue(request, `buttons[0].${buttonType}`, []);
    return buttonArray.length < (name === "QUICK_REPLY" ? 3 : 2);
  };

  const handleRemoveTypeButton = (index: number) => {
    let filtered = getValue(request, `buttons`, []).filter(
      (_item: object, i: number) => i !== index
    );
    setRequest({
      ...request,
      buttons: filtered,
    });
  };

  const getButtonType = (value: string) => {
    const buttonKey = value
      ? value
      : getValue(request, `buttonType`, "") === "QUICK_REPLY"
      ? "quickReply"
      : "callAction";

    return buttonKey;
  };

  /* -------------------------------------------------------------------------- */
  /*                          Template Modal section                            */
  /* -------------------------------------------------------------------------- */

  const [isOpenTemplate, setIsOpenTemplate] = useState(false);
  const handleOpenTempalte = () => {
    setIsOpenTemplate(!isOpenTemplate);
  };
  /* -------------------------------------------------------------------------- */
  /*                            Variable Modal section                          */
  /* -------------------------------------------------------------------------- */

  const [isOpen, setIsOpen] = useState(false);
  const handleModal = () => {
    setIsOpen(!isOpen);
  };
  const editorRef = useRef<any>();

  const handleAddTags = (item: any) => {
    const currentBody = getValue(request, `body`, ""); // Get current body content
    const name = getValue(item, `name`, ""); // Get the name from the item
    const updatedBody = currentBody.endsWith("\n")
      ? currentBody.slice(0, -1)
      : currentBody;

    let text: any = insertText(`[[${name}]]`);
    let finalText =
      text && draftToHtmlPuri(convertToRaw(text.getCurrentContent()));
    setRequest({
      ...request,
      body: finalText,
    });
    request.customTags.push({
      type: "body",
      sample: "",
      placeholder: `[[${getValue(item, `name`, "")}]]`,
      fieldId: getValue(item, `fieldId`, ""),
    });
  };
  const [editorState, setEditorState] = useState(() =>
    EditorState.createEmpty()
  ); // Default state is an empty editor state
  const insertText = (value: any) => {
    const contentState = editorState.getCurrentContent();
    const selectionState = editorState.getSelection();
    const newContentState = Modifier.insertText(
      contentState,
      selectionState,
      value
    );
    const newEditorState = EditorState.push(
      editorState,
      newContentState,
      "insert-characters"
    );
    return newEditorState;
  };

  const handleChangeEditor = (name: any, value: any, editorState: any) => {
    // Assuming you're using a state variable called "editor" to store the editor state
    setEditorState(editorState);
    setRequest({
      ...request,
      body: value,
    });

    // Extract custom tags from the editor text
    const regex = /\[\[(.*?)\]\]/g;
    const matches = value.match(regex);
    if (matches) {
      const newCustomTags = matches.map((match: any) => ({
        type: "body",
        sample: "",
        placeholder: match,
        fieldId: "",
      }));
      let tags = newfun(newCustomTags, request.customTags, allTags);
      setRequest({
        ...request,
        body: value,
        customTags: tags,
      });
    } else {
      setRequest({
        ...request,
        body: value,
        customTags: [],
      });
    }
  };

  /* -------------------------------------------------------------------------- */
  /*                               Pagination section                           */
  /* -------------------------------------------------------------------------- */

  const [totalCount, setTotalCount] = useState(0);
  const [limit, setLimit] = useState(10);
  const [page_no, setPage_no] = useState(1);

  const handleChangePagination = (page_no: number, limit: number) => {
    setPage_no(page_no);
    setLimit(limit);

    let payload = {
      ...UrlParams,
      page_no: page_no,
      page_size: limit,
    };
    let queryRequest = QueryRequestHelper(payload);
    navigate(`${window.location.pathname}?${queryRequest}`);
  };

  const handleChangeLimit = (limit: string) => {
    let payload: any = {
      ...UrlParams,
      limit: limit,
      page_no: "1",
    };
    let queryRequest = QueryRequestHelper(payload);
    navigate(`${window.location.pathname}?${queryRequest}`);
  };

  /* -------------------------------------------------------------------------- */
  /*                                 Helper section                             */
  /* -------------------------------------------------------------------------- */

  const replacePlaceholders = (text: any, data: any) => {
    if (text) {
      let replacedText = parse(text);
      let escapedMatch = replacedText.replace(/\\/g, "");
      data.forEach((item: any) => {
        escapedMatch = escapedMatch.replace(
          `[[${item.name}]]`,
          `[[${item.label}]]`
        );
      });
      return escapedMatch;
    } else {
      return null;
    }
    // return replacedText;
  };
  const replaceDefaultPlaceholders = (text: any, data: any) => {
    let replacedText = convertMarkdownToHtml(text);
    data.forEach((item: any) => {
      replacedText = replacedText.replace(item.placeHolder, `[[${item.name}]]`);
    });
    return replacedText;
  };

  /* -------------------------------------------------------------------------- */
  /*                                 Preview section                            */
  /* -------------------------------------------------------------------------- */

  const [editId, setEditId] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [findInfo, setFindInfo] = useState({});

  const convertCarouselButtons = (buttons: any) => {
    let button =
      getValue(buttons, `length`, 0) > 0
        ? buttons.map((item: object) => ({
            ...item,
            action_type: getValue(item, `type`, ""),
            type: getValue(item, `phone`, "") ? "call" : "url",
          }))
        : [];
    return button;
  };

  /* -------------------------------------------------------------------------- */
  /*                                 Submit section                             */
  /* -------------------------------------------------------------------------- */
  const getCarouselPayload = () => {
    let carousel =
      getValue(request, `carousel.length`, 0) > 0
        ? getValue(request, `carousel`, []).map((item: object) => ({
            header: {
              type: getValue(item, `header.type`, ""),
              text: getValue(item, `header.text`, ""),
              link: getValue(item, `header.link.id`, "")
                ? getValue(item, `header.link.id`, "")
                : getValue(item, `header.link`, ""),
            },
            body: getValue(item, `body`, ""),
            buttons: ConvertedComponent(getValue(item, `buttons`, [])),
          }))
        : [];
    return carousel;
  };
  const convertPayload = () => {
    let buttons = ConvertedComponent(getValue(request, `buttons`, []));
    let payload = JSON.parse(JSON.stringify(request));
    let obj;
    if (getValue(request, `category`, "") === "CAROUSEL") {
      obj = {
        id: getValue(payload, `id`, ""),
        moduleId: moduleId,
        pipelineId: pipelineId,
        category: "MARKETING",
        name: getValue(payload, `name`, ""),
        language: getValue(payload, `language`, ""),
        body: replacePlaceholders(
          getValue(payload, `body`, ""),
          payload.customTags
        ),
        customTags:
          getValue(request, `customTags.length`, 0) > 0
            ? getValue(request, `customTags`, []).map((item: object) => ({
                type: getValue(item, `type`, ""),
                sample: getValue(item, `sample`, ""),
                fieldId: getValue(item, `fieldId`, ""),
              }))
            : [],
        isActive: true,
        carousel: getCarouselPayload(),
      };
    } else {
      obj = {
        id: getValue(payload, `id`, ""),
        moduleId: moduleId,
        pipelineId: pipelineId,
        category: getValue(payload, `category`, ""),
        name: getValue(payload, `name`, ""),
        language: getValue(payload, `language`, ""),
        body: replacePlaceholders(
          getValue(payload, `body`, ""),
          payload.customTags
        ),
        customTags:
          getValue(request, `customTags.length`, 0) > 0
            ? getValue(request, `customTags`, []).map((item: object) => ({
                type: getValue(item, `type`, ""),
                sample: getValue(item, `sample`, ""),
                fieldId: getValue(item, `fieldId`, ""),
              }))
            : [],
        isActive: true,
        header: {
          type: getValue(payload, `header.type`, ""),
          text: getValue(payload, `header.text`, ""),
          link: getValue(payload, `header.link.id`, "")
            ? getValue(payload, `header.link.id`, "")
            : getValue(payload, `header.link`, ""),
        },
        footer: getValue(payload, `footer`, ""),
        buttons: buttons,
      };
    }
    return removeNullOrUndefinedProperties(obj);
  };
  const [submitLoading, setSubmitLoading] = useState(false);
  const handleSubmit = async () => {
    const formValid = simpleValidator.current.allValid();
    if (!formValid) {
      simpleValidator.current.showMessages();
      forceUpdate(1);
    } else {
      try {
        setSubmitLoading(true);
        let resp = await createWhatsappTemplate(
          removeNullOrUndefinedProperties(convertPayload())
        );
        if (resp) {
          handleOpenTempalte();
          resetState();
          if (UrlParams.page_no != "1") {
            handleNavigateHome();
          } else {
            getData();
          }
          toast.success("Submitted successfully");
          hideValidations();
          setSubmitLoading(false);
        } else {
          setSubmitLoading(false);
        }
      } catch (error) {
        setSubmitLoading(false);
      }
    }
  };
  const hideValidations = () => {
    simpleValidator.current.fields = {};
    simpleValidator.current.hideMessages();
    forceUpdate(0);
  };

  /* -------------------------------------------------------------------------- */
  /*                                 Filter section                             */
  /* -------------------------------------------------------------------------- */
  const templateEnumns = [
    "PENDING",
    "APPROVED",
    "REJECTED",
    "IN_APPEAL",
    "PENDING_DELETION",
    "DISABLED",
    "PAUSED",
    "LIMIT_EXCEEDED",
  ];

  const handleFilter = (value: string) => {
    let payload = {
      ...UrlParams,
      status: value,
      page_no: 1,
    };
    let queryRequest = QueryRequestHelper(payload);
    navigate(`${window.location.pathname}?${queryRequest}`);
  };

  const handleSyncWhatsapp = async () => {
    try {
      let resp = await syncWhatsappTemplateStatus("");
      if (resp) {
        getData();
      }
    } catch (error) {}
  };

  /* -------------------------------------------------------------------------- */
  /*                        Carousel Template Section                           */
  /* -------------------------------------------------------------------------- */
  const handleAddCarousel = () => {
    let obj = {
      header: {
        type: "IMAGE",
        link: "",
      },
      body: "",
      buttons: [{ action_type: "" }, { action_type: "" }],
    };
    getValue(request, `carousel`, []).push(obj);
    setRequest({ ...request });
  };

  const handleRemoveCarousel = (index: number) => {
    let filtered = getValue(request, `carousel`, []).filter(
      (_item: object, i: number) => i !== index
    );
    setRequest({
      ...request,
      carousel: filtered,
    });
  };
  const handleCarouseFileChange = async (e: any, i: number) => {
    const file = e.target.files[0];
    let formData = new FormData();
    formData.append("file", file);
    formData.append("filename", getValue(file, `name`, ""));
    formData.append("is_public", "true");
    formData.append("collection", "public-images");
    let resp = await mediaUpload(formData);
    if (resp) {
      setRequest((object: any) => {
        return {
          ...object,
          ["carousel"]: object["carousel"].map((x: any, index: number) => {
            if (index !== i) return x;
            return {
              ...x,
              header: {
                ...x.header,
                link: getValue(resp, "data", {}),
              },
            };
          }),
        };
      });
    }
    if (!file) {
      console.error("No file selected");
      return;
    }
  };

  return (
    <div>
      <div className="other-content subheader_filter_section open">
        <div className="filter-wrapper d-flex align-items-center justify-content-between my-1">
          <div>
            <div style={{ width: "300px" }} className="">
              <Select
                size="middle"
                showSearch
                placeholder="Select a status"
                optionFilterProp="label"
                allowClear
                onChange={(value: string) => handleFilter(value)}
                options={convertElementaryArrayToJSONArray(templateEnumns)}
                style={{
                  width: "240px",
                  height: "40px",
                }}
              />
            </div>
          </div>
          <div className="d-flex align-items-center gap-2">
            <Button size="large" onClick={handleSyncWhatsapp}>
              Sync
            </Button>
            {getValue(props, `permissions`, []).includes("create") && (
              <Button
                size="large"
                type="primary"
                onClick={() => navigate("create-template")}
                icon={<PlusOutlined />}
              >
                New Template
              </Button>
            )}
          </div>
        </div>
      </div>

      {isLoading ? (
        <ListLoader />
      ) : (
        <>
          {getValue(templateList, "length", "") > 0 ? (
            <TemplateTable
              templateList={templateList}
              totalCount={totalCount}
              page_no={page_no}
              page_size={limit}
              handleChangePagination={handleChangePagination}
            />
          ) : (
            <TemplateNoDataPage />
          )}
        </>
      )}

      <CreateWhatsappTemplatePopup
        isOpen={isOpenTemplate}
        handleModal={handleOpenTempalte}
        setRequest={setRequest}
        request={request}
        handleFileChange={handleFileChange}
        handleCarouseFileChange={handleCarouseFileChange}
        handleChangeButton={handleChangeButton}
        handleAddTypeButton={handleAddTypeButton}
        getTypeDisableStatus={getTypeDisableStatus}
        handleRemoveTypeButton={handleRemoveTypeButton}
        getButtonType={getButtonType}
        handleSubmit={handleSubmit}
        submitLoading={submitLoading}
        handleOpenVariable={handleModal}
        handleChangeEditor={handleChangeEditor}
        resetState={resetState}
        editId={editId}
        editorRef={editorRef}
        errorMessage={errorMessage}
        validator={simpleValidator}
        hideValidations={hideValidations}
        handleAddCarousel={handleAddCarousel}
        handleRemoveCarousel={handleRemoveCarousel}
      />
      <AddWhatsappVariable
        isOpen={isOpen}
        handleModal={handleModal}
        handleAddTags={handleAddTags}
        allTags={allTags}
        tagsByPipelineID={tagsByPipelineID}
        allPipelinesList={allPipelinesList}
        pipelineId={pipelineId}
      />
    </div>
  );
}

export default TemplateMessages;
